import * as Cookies from 'js-cookie';
import * as React from 'react';

import { IProfile } from '../types';

const useUserProfile = () => {
  const [profile, setProfile] = React.useState<IProfile | undefined>(undefined);

  const setUserProfile = (action: string, payload: IProfile | undefined) => {
    switch (action) {
      case 'SET':
        if (profile === undefined) {
          setProfile(payload);
        } else {
          setProfile(profile);
          Object.assign(profile as IProfile, payload);
        }
        return profile;
      case 'DELETE':
        setProfile(undefined);
      default:
        return undefined;
    }
  };
  return { profile, setUserProfile };
};
export default useUserProfile;
