import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import LookupLink from 'src/components/LookupLink';
import { authContext } from 'src/contexts/AuthContext';
import { selectedFacilityContext } from 'src/contexts/SelectedFacilitiesContext';
import { IPartForecast, IPartWhereUsed, ISelectedFacilities, IPartForecastQuantities } from 'src/types';
import { ILoadState } from 'src/types/IUtil';
import API from 'src/util/API';
import { ForecastColumn, ForecastFacilityNameColumn, ForecastInventoryColumns, ForecastSummaryColumn } from './ForecastLineElements';
import { forecastDetail, inventoryDetail, getScalarBomQuantity } from './ForecastUtils';
import { detailColumnCount, summaryColumnCount } from './interfaces';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

const bomQuantityDisplay = (bomQty: Record<string, number>, totalPartsCount: number) => {
  const partKeys = Object.keys(bomQty).sort();

  // If only one item show that amount.  otherwise list the detail
  if (totalPartsCount === 1) {
    if (partKeys.length > 1) {
      console.log('More total accumulators than targets');
      return <span>'???'</span>;
    }
    return bomQty[partKeys[0]];
  }
  if (partKeys.length === 1) {
    const p = partKeys[0];
    return <span className="uk-text-small" > {p}: {bomQty[p]} <br /></span >;
  }
  return <ul data-uk-accordion className="uk-margin-remove-bottom">
    <li>
      <a className="uk-accordion-title uk-text-small" href="#">{partKeys.length} items</a>
      <div className="uk-accordion-content uk-text-small">
        {partKeys.map((p) => <Fragment>{p}: {bomQty[p]} <br /></Fragment>)}
      </div>
    </li>
  </ul>
}

export const ForecastLine = React.memo(({ part, totalPartsCount, showZero, showDetail, updateTotals }:
  {
    part: IPartForecastQuantities,
    showZero: boolean,
    totalPartsCount: number,
    showDetail: boolean,
    updateTotals: any,
  }): React.ReactElement => {

  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);
  const [forecast, setForecast] = useState<IPartForecast>();
  const [error, setError] = useState('');
  const { selectedFacilities } = useContext(selectedFacilityContext);

  const getForecast = async (partNo: string) => {
    API.get(authToken, `/api/v1/parts/${partNo}/forecast`, { timeout: 30000 }).then((p) => {
      const forecastPart: IPartForecast = p.data;
      setForecast(forecastPart);
      updateTotals('UPDATE', { forecastPart, partQty: part.bomQuantities });
      setDisplayState(ILoadState.READY);
    })
      .catch((err) => {
        updateTotals('UPDATE', { forecastPart: { client_part_number: partNo, forecast: [] }, partQty: {} });
        setDisplayState(ILoadState.READY);
        console.log('Forecast got an error:' + JSON.stringify(err));
        if (err.status === 404) {
          setForecast(
            {
              asOfDate: new Date(),
              client_part_number: 'not found',
              description: 'not found',
              facility: {},
            });
        } else {
          setForecast(
            {
              asOfDate: new Date(),
              client_part_number: partNo,
              description: JSON.stringify(err),
              facility: {},
            });
          setError(JSON.stringify(err));
          setDisplayState(ILoadState.ERROR);
        }
        return;
      });
    setDisplayState(ILoadState.LOADING);
  };

  // Get the data for this line on first render
  useEffect(() => {
    getForecast(part.client_part_number);
  }, []);

  let total = 0;
  const forecastData = forecast ? forecast.facility : {};
  for (let i = 0; i < detailColumnCount; i++) {
    total += forecastDetail(forecastData, i, part.bomQuantities, totalPartsCount).reduce((a, c) => a + c, 0);
  }
  total += inventoryDetail(forecastData, 'inventory', part.bomQuantities, totalPartsCount)
    .reduce((a, c) => a + c, 0);
  total += inventoryDetail(forecastData, 'received', part.bomQuantities, totalPartsCount)
    .reduce((a, c) => a + c, 0);
  total += inventoryDetail(forecastData, 'suspension', part.bomQuantities, totalPartsCount)
    .reduce((a, c) => a + c, 0);

  const hasData = forecast && total > 0;

  if (displayState === ILoadState.NEW ||
    displayState === ILoadState.LOADING) {
    return <LoadingLine
      part={part}
      showDetail={showDetail} />;
  }

  if (displayState === ILoadState.ERROR) {
    return <ErrorLine
      part={part}
      showDetail={showDetail}
      error={error} />
  }
  if (!forecast || // forecast.forecast.length === 0 ||
    (!showZero && !hasData)
  ) {
    return <ZeroLine
      showZero={showZero}
      part={part}
      totalPartsCount={totalPartsCount}
      showDetail={showDetail} />
      ;
  }

  return <ForecastDataLine
    part={part}
    showDetail={showDetail}
    totalPartsCount={totalPartsCount}
    forecast={forecast}
    selectedFacilities={selectedFacilities} />
    ;
}, (prev, next) => {
  return prev.part.client_part_number === next.part.client_part_number &&
    prev.showZero === next.showZero &&
    prev.showDetail === next.showDetail;
});

const LoadingLine = ({ part, showDetail }:
  {
    part: IPartForecastQuantities,
    showDetail: boolean,
  }): React.ReactElement => {
  return (
    <tr key={part.client_part_number}>
      <td style={{ textAlign: 'left' }}>
        {part.client_part_number} <span className="uk-text-small uk-text-muted">{part.description}</span>
      </td>
      <td >
      </td>
      <td><span data-uk-spinner></span></td>
      <td></td>
      <td></td>
      <td colSpan={showDetail ? detailColumnCount : summaryColumnCount}></td>
      <td></td>
    </tr>
  );
};

const ErrorLine = ({ part, showDetail, error }:
  {
    part: IPartForecastQuantities,
    showDetail: boolean,
    error: string,
  }): React.ReactElement => {
  return (
    <tr key={part.client_part_number} className="uk-text-danger">
      <td style={{ textAlign: 'left' }}>
        {part.client_part_number} <span className="uk-text-small uk-text-muted">{part.description}</span>
      </td>
      <td >
      </td>
      <td colSpan={3}>Error loading forecasting data: {part.description}</td>
      <td colSpan={showDetail ? detailColumnCount : summaryColumnCount}></td>
      <td></td>
    </tr>
  );
}

const ZeroLine = ({ part, totalPartsCount, showDetail, showZero }:
  {
    part: IPartForecastQuantities,
    totalPartsCount: number,
    showDetail: boolean,
    showZero: boolean,
  }): React.ReactElement => {
  if (!showZero) {
    return <Fragment />;
  }
  return (
    <tr key={part.client_part_number}>
      <td style={{ textAlign: 'left' }}>
        {part.client_part_number} <span className="uk-text-small uk-text-muted">{part.description}</span>
      </td>
      <td >
        <span className="uk-text-lighter">
          {bomQuantityDisplay(part.bomQuantities, totalPartsCount)}
        </span>
      </td>
      <td colSpan={showDetail ? detailColumnCount + 4 : summaryColumnCount + 4}>
        No forecast data
        </td>
      <td className="uk-text-nowrap">
        <LookupLink part={part.client_part_number} />
      </td>
    </tr >
  );
};

const ForecastDataLine = ({ part, totalPartsCount, showDetail, forecast, selectedFacilities }:
  {
    part: IPartForecastQuantities,
    showDetail: boolean,
    totalPartsCount: number,
    forecast: IPartForecast,
    selectedFacilities: ISelectedFacilities,
  }): React.ReactElement => {
  if (showDetail) {
    return <ForecastDataDetailLine
      part={part}
      totalPartsCount={totalPartsCount}
      forecast={forecast}
      selectedFacilities={selectedFacilities}
    />;
  } else {
    return <ForecastDataSummaryLine
      part={part}
      totalPartsCount={totalPartsCount}
      forecast={forecast}
      selectedFacilities={selectedFacilities}
    />;
  }
};

const ForecastDataSummaryLine = ({ part, totalPartsCount, forecast, selectedFacilities }:
  {
    part: IPartForecastQuantities,
    totalPartsCount: number,
    forecast: IPartForecast,
    selectedFacilities: ISelectedFacilities,
  }): React.ReactElement => {
  return (
    <tr key={`${part.client_part_number}_tr`}>
      < td className="uk-text-left" >
        {part.client_part_number} < span className="uk-text-small uk-text-muted" > {part.description}</span >
      </td >
      <td >
        <span className="uk-text-lighter">
          {bomQuantityDisplay(part.bomQuantities, totalPartsCount)}
        </span>
      </td>
      <ForecastFacilityNameColumn selectedFacilities={selectedFacilities} />
      <ForecastInventoryColumns forecast={forecast.facility} partQuantity={part.bomQuantities} totalPartsQuantity={totalPartsCount} />
      <ForecastColumn forecast={forecast.facility} index={0} partQuantity={part.bomQuantities} totalPartsQuantity={totalPartsCount} />
      {[...Array(summaryColumnCount - 3).keys()].map((i) =>
        <ForecastColumn key={`col+${i}`} forecast={forecast.facility} index={i + 1} partQuantity={part.bomQuantities} totalPartsQuantity={totalPartsCount} />
      )}
      <ForecastSummaryColumn
        forecast={forecast.facility}
        start={summaryColumnCount - 2}
        end={summaryColumnCount + 5}
        partQuantity={part.bomQuantities}
        totalPartsQuantity={totalPartsCount} />
      <ForecastSummaryColumn
        forecast={forecast.facility}
        start={summaryColumnCount + 5}
        end={detailColumnCount}
        partQuantity={part.bomQuantities}
        totalPartsQuantity={totalPartsCount} />
      <td>
        {dayjs(new Date(forecast.asOfDate)).fromNow()}
      </td>
      <td className="uk-text-nowrap">
        <LookupLink part={part.client_part_number} />
      </td>
    </tr >
  );
};

const ForecastDataDetailLine = ({ part, totalPartsCount, forecast, selectedFacilities }:
  {
    part: IPartForecastQuantities,
    totalPartsCount: number,
    forecast: IPartForecast,
    selectedFacilities: ISelectedFacilities,
  }): React.ReactElement => {
  return (
    <tr key={`${part.client_part_number}_tr`}>
      < td className="uk-text-left" >
        {part.client_part_number} < span className="uk-text-small uk-text-muted" > {part.description}</span >
      </td >
      <td >
        <span className="uk-text-lighter">
          {bomQuantityDisplay(part.bomQuantities, totalPartsCount)}
        </span>
      </td>
      <ForecastFacilityNameColumn selectedFacilities={selectedFacilities} />
      <ForecastInventoryColumns forecast={forecast.facility} partQuantity={part.bomQuantities} totalPartsQuantity={totalPartsCount} />
      <ForecastColumn forecast={forecast.facility} index={0} partQuantity={part.bomQuantities} totalPartsQuantity={totalPartsCount} />
      {
        [...Array(detailColumnCount - 1).keys()].map((i) =>
          <ForecastColumn key={`col_${i}`} forecast={forecast.facility} index={i + 1} partQuantity={part.bomQuantities} totalPartsQuantity={totalPartsCount} />
        )}
      <td>
        {dayjs(new Date(forecast.asOfDate)).fromNow()}
      </td>
      <td className="uk-text-nowrap">
        <LookupLink part={part.client_part_number} />
      </td>
    </tr >
  );
};
