import React, { Fragment, useContext, useEffect, useState } from 'react';

import { IForecast, ISelectedFacilities } from 'src/types';
import { forecastDetail, inventoryDetail, selectedFacilityNames, summaryForecastDetail } from './ForecastUtils';

export const ForecastFacilityNameColumn = ({ selectedFacilities }:
  {
    selectedFacilities: ISelectedFacilities,
  }): React.ReactElement => {
  return (
    <td>
      {selectedFacilityNames(selectedFacilities)
        .map((a, i) => <Fragment key={`fac_${i}`}>{a} <br /></Fragment>)}
    </td>
  );
};

export const ForecastInventoryColumns = ({ forecast, partQuantity, totalPartsQuantity }:
  {
    forecast: Record<string, IForecast>,
    partQuantity: Record<string, number>,
    totalPartsQuantity: number
  }): React.ReactElement => {
  return (
    <Fragment>
      <td>
        {inventoryDetail(forecast, 'inventory', partQuantity, totalPartsQuantity)
          .map((a, i) => <Fragment key={`inv_${i}`}>{a} <br /></Fragment>)}
      </td>
      <td>
        {inventoryDetail(forecast, 'suspension', partQuantity, totalPartsQuantity)
          .map((a, i) => <Fragment key={`susp_${i}`}>{a} <br /></Fragment>)}
      </td>
      <td>
        {inventoryDetail(forecast, 'received', partQuantity, totalPartsQuantity)
          .map((a, i) => <Fragment key={`rcv_${i}`}>{a} <br /></Fragment>)}
      </td>
    </Fragment>
  );
};

export const ForecastColumn = ({ forecast, index, partQuantity, totalPartsQuantity }:
  {
    forecast: Record<string, IForecast>,
    index: number,
    partQuantity: Record<string, number>,
    totalPartsQuantity: number
  }): React.ReactElement => {
  return (
    <td>
      {forecastDetail(forecast, index, partQuantity, totalPartsQuantity)
        .map((a, i) => <Fragment key={`fc_${i}`}>{a} <br /></Fragment>)}
    </td>
  );
};

export const ForecastSummaryColumn = ({ forecast, start, end, partQuantity, totalPartsQuantity }:
  {
    forecast: Record<string, IForecast>,
    start: number,
    end: number,
    partQuantity: Record<string, number>,
    totalPartsQuantity: number
  }): React.ReactElement => {
  return (
    <td>
      {summaryForecastDetail(forecast, start, end, partQuantity, totalPartsQuantity)
        .map((a, i) => <Fragment key={`sum_${i}`} > {a} < br /></Fragment>)}
    </td>
  );
};
