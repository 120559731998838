
import React from 'react';
import { Link } from 'react-router-dom';

interface ILinkProps {
  part: string;
}
export default function LookupLink({ part }: ILinkProps) {
  return <Link to={{ pathname: '/FBLookup', search: `part=${part}&mode=bom` }}
    className="uk-padding-remove-right uk-preserve-width"
    uk-icon="hierarchy" />;
}
