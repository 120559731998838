import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import queryString from 'query-string';
import React, { Fragment, MouseEvent, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Alert, { AlertTypes } from '../components/AlertDisplay';
import LookupLink from '../components/LookupLink';
import Scanner from '../components/Scanner';
import WhereUsedLink from '../components/WhereUsedLink';
import { authContext } from '../contexts/AuthContext';
import * as Types from '../types';
import API from '../util/API';
import { QuaggaJSResultObject } from '@ericblade/quagga2';

dayjs.extend(relativeTime);

interface ISerialPartListProps {
  rackParts: Types.ISerialResponse;
  selectedSerial: string;
}
interface IPartNotFoundProps {
  loadState: Types.ILoadState;
}
interface IPartTableProps {
  parts: Types.IPartWhereUsed[];
}

export default function SerialLookup() {

  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [serialNumber, setSerialNumber] = useState('');
  const [rackParts, setRackParts] = useState<Types.ISerialResponse | undefined>(undefined);
  const [loadState, setLoadState] = useState<Types.ILoadState>(Types.ILoadState.NEW);
  const [serialInput, setSerialInput] = useState('');
  const [scanning, setScanning] = useState(false);
  const scannerRef = useRef(null);
  const history = useHistory();

  const getRackParts = (serial: string) => {

    const values = queryString.parse(window.location.search);

    setScanning(false);
    API.get(authToken, `/api/v1/inventory/serial/${serial}`,
      { timeout: 10000 }).then((serialResponse) => {
        setSerialNumber(serial);
        setLoadState(Types.ILoadState.READY);
        setRackParts(serialResponse.data);
        history.push({
          search: `?serial=${serial}`,
        });
      }).catch((err) => {
        setSerialNumber(serial);
        setRackParts(undefined);
        if (err.status == 404) {
          setLoadState(Types.ILoadState.NOTFOUND);
        } else {
          Alert(AlertTypes.ERROR, err.message);
          setLoadState(Types.ILoadState.NEW);
        }
      });
  };

  React.useEffect(() => {
    const values = queryString.parse(window.location.search);
    const queryPart = values.serial;
    if (queryPart && authToken) {
      const newPart = Array.isArray(queryPart) ? queryPart[0] : queryPart;
      getRackParts(newPart);
    }
  }, []);

  /* hide the injected video frame when scanning is off */
  React.useEffect(() => {
    if (document) {
      const elp = document.getElementById('scannerbox') as HTMLDivElement;
      if (elp) {
        const el = elp.firstElementChild;
        if (el && el.nodeName === 'VIDEO') {
          const elv = el as HTMLVideoElement;
          console.log('Setting video visibiilty: ' + scanning);
          elv.style.height = scanning ? 'auto' : '1px';
        }
      }
    }
  }, [scanning]);

  const onScanResult = (scanResult: string) => {
    console.log('scanned something: ' + scanResult);
    getRackParts(scanResult);
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialInput(event.target.value);
  };

  const onScanButtonClick = (event: React.FormEvent) => {
    setLoadState(Types.ILoadState.NEW);
    console.log('button clicked when scanning = ' + scanning);
    setScanning(!scanning);
  }

  const handleEditAction = (event: React.FormEvent) => {
    event.preventDefault();
    if (serialInput && serialInput.length > 0) {
      getRackParts(serialInput);
    }
  };

  return (
    <div className="uk-margin-small uk-padding-left uk-margin-left uk-margin-right uk-child-width-1-1" >
      <h3>Serial Number Lookup</h3>
      <div className="uk-grid" >
        <form onSubmit={(e) => { handleEditAction(e); }}>
          <input type="text"
            className="uk-input uk-width-medium uk-inline"
            key={serialNumber}
            defaultValue={serialNumber}
            onChange={(e) => { onChange(e); }} />
          <button type="submit"
            className="uk-button uk-button-primary uk-inline" >Go</button>

          <button className="uk-button uk-button-secondary uk-inline"
            onClick={(e) => onScanButtonClick(e)} > {scanning ? 'Stop' : 'Scan'}</button>

          <div id="scannerbox"
            ref={scannerRef}
            style={{ visibility: scanning ? 'visible' : 'hidden', position: 'relative', border: '3px solid gray' }}>
            {/* <video style={{ width: window.innerWidth, height: 480, border: '3px solid orange' }}/> */}
            {scanning ?
              <canvas className="drawingBuffer" style={{
                position: 'absolute',
                top: '0px',
                // left: '0px',
                // height: '40px',
                // width: '100px',
                // border: '3px solid green',
              }} width="640" height="480" />
              : null}
            {scanning ?
              <Scanner
                scannerRef={scannerRef}
                onDetected={(result) => onScanResult(result)}
              />
              : null}
          </div>

        </form>
      </div>
      <div className="uk-align-left uk-overflow-auto">
        {(loadState === Types.ILoadState.NOTFOUND ||
          (loadState === Types.ILoadState.READY && !rackParts)) &&
          <PartNotFound loadState={loadState} />}
        {loadState === Types.ILoadState.LOADING &&
          <div data-uk-spinner="ratio: 3" className="uk-position-center"></div>}
        {loadState === Types.ILoadState.READY && rackParts &&
          <SerialPartList rackParts={rackParts} selectedSerial={serialNumber} />}
      </div>
    </div >
  );
}

const PartNotFound = ({ loadState }: IPartNotFoundProps): React.ReactElement => {
  if (loadState === Types.ILoadState.NOTFOUND) {
    return (
      <div className="uk-alert uk-alert-danger">
        <h4>Part not found</h4>
      </div>);
  } else {
    return <Fragment />;
  }
};

const SerialPartList = ({ rackParts, selectedSerial }: ISerialPartListProps): React.ReactElement => {
  const formatDate = (d: Date) => {
    const format = 'YYYY-MM-D';
    if (dayjs(d).isBefore(dayjs(), 'day')) {
      return dayjs(d).format(format);
    } else {
      return '(' + dayjs(d).format(format) + ')';
    }
  };

  return (
    <div className="uk-padding uk-padding-remove-left">
      <h4>Full rack server components from FB DCom data shown below. Requested item is highlighted.</h4>
      <table className="uk-table uk-table-divider ">
        <thead>
          <tr>
            <th> Type </th>
            <th> Serial Number </th>
            <th> FB Part Number </th>
            <th> Origin Data Center </th>
            <th> WITS Status </th>
            <th> WITS Location </th>
            <th> Received Date (Expected By) </th>
            <th>Disposition</th>
            <th>FB Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {[rackParts.rack, ...rackParts.servers, ...rackParts.power].filter((p) => p).map((p) => {
            return (
              <tr className={p.serialNumber === selectedSerial ? 'uk-background-secondary ' : ''}>
                <td>{p.deviceType}</td>
                <td>{p.serialNumber}</td>
                <td>{p.FBPartNo}</td>
                <td>{p.FBDataCenter}</td>
                <td>{p.WITSStatus}</td>
                <td>{p.WITSLocation}</td>
                <td>{formatDate(p.receivedDate)}</td>
                <td>{p.dispositionNote ? <span className="uk-text-danger uk-text-bold">{p.dispositionNote}</span> : 'Normal'}</td>
                <td className="uk-text-small">{p.FBPartDescription}</td>
                <td>
                  {p.FBPartNo && <span className="uk-align-right uk-text-nowrap">
                    <LookupLink part={p.FBPartNo} />
                    <WhereUsedLink part={p.FBPartNo} />
                  </span>
                  }
                </td>
              </tr>
            );
          })
          }
        </tbody>
      </table>
    </div >
  );
};
