import * as Cookies from 'js-cookie';
import * as React from 'react';

const useAuthStatus = () => {

  //  [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>] {
  const [authToken, setAuthToken] = React.useState(Cookies.get('authToken'));

  const setAuthStatus = (action: string, newToken: string | undefined) => {
    switch (action) {
      case 'SET':
        if (newToken != undefined) {
          Cookies.set('authToken', newToken, { expires: 14, sameSite: 'Lax' });
          setAuthToken(newToken);
        }
        return newToken;
      case 'LOGOUT':
        Cookies.remove('authToken');
        setAuthToken(undefined);
        return undefined;
      default:
        // raise sort of error...
        return undefined;
    }
  };
  return { authToken, setAuthStatus };
};

export default useAuthStatus;
