import * as React from 'react';

import useAuthStatus from '../hooks/authHook';
import useUserProfile from '../hooks/userProfileHook';
import { IProfile } from '../types';

export interface IAuthContextInterface {
  authToken: string | undefined;
  profile: IProfile | undefined;
  setAuthStatus: (action: string, newToken: string | undefined) => string | undefined;
  setUserProfile: (action: string, userProfile: IProfile | undefined) => IProfile | undefined;
}

export const authContext = React.createContext<IAuthContextInterface>(
  {
    authToken: undefined,
    profile: undefined,
    setAuthStatus: (): string | undefined => { return; },
    setUserProfile: (): IProfile | undefined => { return; },
  });

const { Provider } = authContext;

const AuthProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const { authToken, setAuthStatus } = useAuthStatus();
  const { profile, setUserProfile } = useUserProfile();

  return (
    <Provider value={{ authToken, setAuthStatus, profile, setUserProfile }}>
      {children}
    </Provider>
  );
};

export default AuthProvider;
