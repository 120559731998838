import { authContext, IAuthContextInterface } from '../contexts/AuthContext';
import { config } from './../config';
import API from './../util/API';

export function loadProfile(bearerToken: string, auth: IAuthContextInterface) {
  API.get(bearerToken, `${config.AUTH_SERVER_URL}/api/v1/users/profile`).then((profileResponse) => {
    auth.setUserProfile('SET', {
      displayName: profileResponse.data.displayName,
      email: profileResponse.data.email,
      username: profileResponse.data.username,
    });
  });
}
