import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiError } from './ApiError';

export interface IAPIConfig {
  timeout?: number; // override default timeout.
  failSilently?: boolean; // Don't complain if this fails.  good for autocomplete e.g.
  skipQueue?: boolean;
}

export default class API {

  public static async post(
    auth: string | undefined,
    path: string,
    payload: object,
    apiConfig?: IAPIConfig): Promise<any> {

    const axiosConfig = API.axiosConfig;
    if (auth) {
      axiosConfig.headers.Authorization = `Bearer ${auth}`;
    }

    if (apiConfig) {
      if (apiConfig.timeout) {
        axiosConfig.timeout = apiConfig.timeout;
      }
    }

    const apiResult = await Axios.post(API.config.baseURL + path, payload, axiosConfig).then((result) => {
      if (result.data && result.data.result === 'redirect' && result.data.redirect) {
        window.location.href = result.data.redirect;
      }
      return result;
    }).catch((err) => {
      if (apiConfig && !apiConfig.failSilently) {
        API.httpError(err);
      }
    });
    return apiResult;
  }

  public static async patch(
    auth: string | undefined,
    path: string,
    payload: object,
    apiConfig?: IAPIConfig): Promise<any> {

    const axiosConfig = API.axiosConfig;
    if (auth) {
      axiosConfig.headers.Authorization = `Bearer ${auth}`;
    }

    if (apiConfig) {
      if (apiConfig.timeout) {
        axiosConfig.timeout = apiConfig.timeout;
      }
    }

    const apiResult = await Axios.patch(API.config.baseURL + path, payload, axiosConfig).then((result) => {
      if (result.data && result.data.result === 'redirect' && result.data.redirect) {
        window.location.href = result.data.redirect;
      }
      return result;
    }).catch((err) => {
      if (!apiConfig || (apiConfig && !apiConfig.failSilently)) {
        API.httpError(err);
      }
    });
    return apiResult;
  }

  public static async get(auth: string | undefined, path: string, apiConfig?: IAPIConfig): Promise<any> {
    API.initialize();

    const axiosConfig = API.axiosConfig;
    if (auth) {
      axiosConfig.headers.Authorization = `Bearer ${auth}`;
    }

    if (apiConfig) {
      if (apiConfig.timeout) {
        axiosConfig.timeout = apiConfig.timeout;
      }
    }

    const apiResult = API.axiosGet.get(API.config.baseURL + path, axiosConfig).then((result: AxiosResponse<any>) => {
      return result;
    }).catch((err: any) => {
      if (!apiConfig || !apiConfig.failSilently) {
        API.httpError(err);
      }
    });
    return apiResult;

  }

  public static async delete(
    auth: string | undefined,
    path: string,
    payload: object,
    apiConfig?: IAPIConfig): Promise<any> {

    const axiosConfig = API.axiosConfig;
    if (auth) {
      axiosConfig.headers.Authorization = `Bearer ${auth}`;
    }
    axiosConfig.data = payload;

    if (apiConfig) {
      if (apiConfig.timeout) {
        axiosConfig.timeout = apiConfig.timeout;
      }
    }

    const apiResult = await Axios.delete(API.config.baseURL + path, axiosConfig).then((result) => {
      if (result.data && result.data.result === 'redirect' && result.data.redirect) {
        window.location.href = result.data.redirect;
      }
      return result;
    }).catch((err) => {
      if (apiConfig && !apiConfig.failSilently) {
        API.httpError(err);
      }
    });
    return apiResult;
  }
  public static server(): string {
    return API.config.baseURL;
  }

  // tslint:disable-next-line
  private static _initialized = false;
  private static axiosGet: any;

  private static PENDING_REQUESTS = 0;
  private static MAX_REQUESTS_COUNT = 10;
  private static INTERVAL_MS = 10;
  private static DRAIN = false;

  private static config = {
    baseURL: '',
  };
  private static axiosConfig: AxiosRequestConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    responseType: 'json',
    timeout: 2000,  // milliseconds
  };

  private static initialize() {
    if (!API._initialized) {
      API.axiosGet = Axios.create({});
      API._initialized = true;
      API.axiosGet.interceptors.request.use((config: any) => {
        return new Promise((resolve, reject) => {
          const interval = setInterval(() => {
            if (config.skipQueue || API.PENDING_REQUESTS < API.MAX_REQUESTS_COUNT) {
              API.PENDING_REQUESTS++;
              clearInterval(interval);
              resolve(config);
            }
          }, API.INTERVAL_MS);
        });
      });
      API.axiosGet.interceptors.response.use((response: any) => {
        API.PENDING_REQUESTS = Math.max(0, API.PENDING_REQUESTS - 1);
        return Promise.resolve(response);
      }, (error: any) => {
        API.PENDING_REQUESTS = Math.max(0, API.PENDING_REQUESTS - 1);
        return Promise.reject(error);
      });
    }
  }

  private static httpError(err: any): void {
    console.log('API Error: ' + JSON.stringify(err));
    if (err.response) {
      console.log('API Error Response: ' + JSON.stringify(err.response));
      throw new ApiError('Server Error', err.response.status, err.response.message || err.response.statusText);
    }
    if (err.message) {
      if (err.message.match(/timeout of \d+ms exceeded/)) {
        throw new ApiError('Network Error', err.status, 'No response from server');
      }
      throw new ApiError('Network Error', err.status, err.message);
    } else {
      throw new ApiError('Unknown API Error', -1, 'Unknown API Error');
    }
  }

}
