import * as React from 'react';
import { ISelectedFacilities } from 'src/types';

const useSelectedFacilities = () => {
  const [selectedFacilities, setFacilities] = React.useState<ISelectedFacilities>(new Map());

  const setSelectedFacilities = (newFacilities: ISelectedFacilities) => {
    setFacilities(newFacilities);
    return selectedFacilities;
  };

  return { selectedFacilities, setSelectedFacilities };
};

export default useSelectedFacilities;
