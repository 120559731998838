import React, { useContext, useEffect, useState } from 'react';

import { IPartForecast, IPartForecastQuantities } from 'src/types';

import { ForecastLine } from './ForecastLine';
import { ForecastTotalLine } from './ForecastTotalLine';

import { detailColumnCount, detailHeaders, IFacilityTotals, IStateTotals, summaryHeaders } from './interfaces';

interface IUpdateTotalsProps {
  remaining?: number;
  forecastPart?: IPartForecast;
  partQty?: Record<string, number>;
}

function log(msg: string) {
  console.log(msg);
}

export function ForecastTable({ targetPartList, componentPartList, showZero, showDetail }:
  {
    targetPartList: string[],
    componentPartList: IPartForecastQuantities[],
    showZero: boolean,
    showDetail: boolean,
  }): React.ReactElement {

  const [totals, setTotals] = useState<IStateTotals>({
    parts: {},
    remaining: -1,
  });
  const [parts, setParts] = useState<{ [key: string]: number }>({});
  const [key, setKey] = useState(0);

  function useUpdateTotals(action: string, { remaining, forecastPart, partQty }: IUpdateTotalsProps): IStateTotals {
    // log('updating totals');
    if (action === 'SETMAX') {
      if (remaining && totals.remaining === -1) {
        totals.remaining = remaining;
        totals.parts = Object.keys(parts).reduce(
          (t: Record<string, IFacilityTotals>, p: string) => { t[p].facility = {}; return t },
          {} as Record<string, IFacilityTotals>);
        setTotals(totals);
      }
    } else if (action === 'UPDATE') {
      if (!forecastPart) {
        return totals;
      }
      // log('total part: ' + forecastPart);
      if (!parts[forecastPart.client_part_number]) {
        parts[forecastPart.client_part_number] = 1;
      } else {
        parts[forecastPart.client_part_number]++;
      }


      const facilities = Object.keys(forecastPart.facility || {});
      /**** perf diagnostic
    if (parts[param.forecastPart.client_part_number]) {
        log('Updating ' + parts[param.forecastPart.client_part_number] + ' times');
      }
      */
      // log('totals remaining: ' + totals.remaining);
      if (totals.remaining && partQty) {
        // log('Facility list: ' + JSON.stringify(facilities));
        Object.keys(partQty).forEach((totalPartNo) => {

          facilities.forEach((f) => {
            // log('updating totals for ' + f);
            /*
            if (parts[forecastPart.client_part_number] === 1) {
              param.partQty = param.partQty || 0;
            }
            */
            //          log('Total length (' + f + '): ' + totals.facility[f].totals.length +
            //           ' - ' + forecastPart.facility[f].forecast.length);

            if (!totals.parts[totalPartNo]) {
              totals.parts[totalPartNo] = { facility: {} };
            }
            if (!(f in totals.parts[totalPartNo].facility)) {
              totals.parts[totalPartNo].facility[f] = {
                forecast: Array(detailColumnCount).fill({ days: 0, quantity: 0 }),
                inventory_status: { suspension: 0, received: 0, inventory: 0, disposed: 0 },
              };
              totals.parts[totalPartNo].facility[f].forecast = forecastPart.facility[f].forecast
                .map((a) => { return { days: a.days, quantity: a.quantity * partQty[totalPartNo] }; });
            } else {
              for (let i = 0;
                i < Math.min(totals.parts[totalPartNo].facility[f].forecast.length, forecastPart.facility[f].forecast.length); i++) {

                totals.parts[totalPartNo].facility[f].forecast[i].quantity += forecastPart.facility[f].forecast[i].quantity * partQty[totalPartNo];
              }
            }
            totals.parts[totalPartNo].facility[f].inventory_status.suspension += forecastPart.facility[f].inventory_status.suspension * partQty[totalPartNo];
            totals.parts[totalPartNo].facility[f].inventory_status.received += forecastPart.facility[f].inventory_status.received * partQty[totalPartNo];
            totals.parts[totalPartNo].facility[f].inventory_status.inventory += forecastPart.facility[f].inventory_status.inventory * partQty[totalPartNo];
          });
        });
        totals.remaining--;
        setParts(parts);
        setTotals(totals);
      }
    }
    setKey(totals.remaining);
    return totals;
  }

  useEffect(() => {
    useUpdateTotals('SETMAX', { remaining: componentPartList.length });
  }, [componentPartList.length]);

  return (
    <table className="uk-table uk-table-divider uk-table-small uk-margin-right-small">
      <thead>
        <tr>
          <th className="uk-text-muted uk-text-small"><i>Inventory Status by TLA only</i></th>
          <th />
          <th />
          <th colSpan={3} className="uk-background-secondary uk-text-emphasis">
            Extended Quantity - Inventory
          </th>
          <th colSpan={11} className="uk-background-muted">
            Extended Quantity - Forecast
          </th>
          <th />
          <th />
        </tr>
        <tr>
          <th>
            Top Level Part
          </th>
          <th>
            Units in Assembly
          </th>
          <th>
            Location
          </th>
          <th>
            Inventory
          </th>
          <th>
            Suspension
          </th>
          <th>
            Received
          </th>
          {showDetail
            ? detailHeaders.map((h, i) => <th key={`tableheader_${i}`}>{h}</th>)
            : summaryHeaders.map((h, i) => <th key={`tableheader_${i}`}>{h}</th>)
          }
          <th>
            Last Updated
          </th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody className="uk-accordion">
        {targetPartList.map((p) =>
          <ForecastTotalLine
            key={`total-${p}`}
            part={p}
            showDetail={showDetail}
            totals={totals} />
        )}
        {componentPartList.map((p, i) =>
          <ForecastLine
            key={`line_${i}`}
            part={p}
            totalPartsCount={targetPartList.length}
            showZero={showZero}
            showDetail={showDetail}
            updateTotals={useUpdateTotals} />)}
      </tbody>
    </table >
  );
}
