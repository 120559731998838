import { useContext } from 'react';

import { selectedFacilityContext } from 'src/contexts/SelectedFacilitiesContext';
import { IForecast, InventoryStatusType, IPartForecastQuantities } from 'src/types';

/*
 * Utiltity function to return a quantity of 1 if we have more than one part we're working on
 */
export const getScalarBomQuantity = (bomQuantity: Record<string, number>, totalPartsCount: number) => {
  if (totalPartsCount !== 1) {
    return 1;
  }
  const parts = Object.keys(bomQuantity);
  // mostly the total line
  if (parts.length === 0) {
    return 1;
  }
  return bomQuantity[parts[0]];
}

export const inventoryDetail = (amounts: Record<string, IForecast>,
  status: InventoryStatusType,
  bomQuantities: Record<string, number>,
  totalPartsCount: number,
) => {

  const { selectedFacilities } = useContext(selectedFacilityContext);
  let total = 0;
  let facilityCount = 0;
  const facilityAmts: number[] = [];
  const partQty = getScalarBomQuantity(bomQuantities, totalPartsCount);

  // calculate the sum total, and an array of selected facilities. Then return the sum if no facilities selected
  [...selectedFacilities.keys()].sort().forEach((f) => {
    const amt = amounts[f] ? amounts[f].inventory_status[status] * partQty : 0;
    total += amt;
    if (selectedFacilities.get(f)) {
      facilityAmts.push(amt);
      facilityCount++;
    }
  });
  if (facilityCount) {
    return facilityAmts;
  }
  return [total];
};

export const forecastDetail = (forecast: Record<string, IForecast>,
  index: number,
  bomQuantities: Record<string, number>,
  totalPartsCount: number) => {

  const { selectedFacilities } = useContext(selectedFacilityContext);
  let total = 0;
  let facilityCount = 0;
  const facilityAmts: number[] = [];

  // Multiply by bom quantity if only one forecast part
  const partQty = getScalarBomQuantity(bomQuantities, totalPartsCount);

  [...selectedFacilities.keys()].sort().forEach((f) => {
    const amt = forecast[f] ? forecast[f].forecast[index].quantity * partQty : 0;
    total += amt;
    if (selectedFacilities.get(f)) {
      facilityAmts.push(amt);
      facilityCount++;
    }
  });
  if (facilityCount) {
    return facilityAmts;
  }
  return [total];
};

export const summaryForecastDetail = (forecast: Record<string, IForecast>,
  start: number,
  end: number,
  bomQuantities: Record<string, number>,
  totalPartsCount: number) => {

  const { selectedFacilities } = useContext(selectedFacilityContext);
  let total = 0;
  let facilityCount = 0;
  const facilityAmts: number[] = [];
  const partQty = getScalarBomQuantity(bomQuantities, totalPartsCount);

  [...selectedFacilities.keys()].sort().forEach((f) => {
    let amt = 0;
    for (let i = start; i < end; i++) {
      amt += forecast[f] ? forecast[f].forecast[i].quantity * partQty : 0;
    }
    total += amt;
    if (selectedFacilities.get(f)) {
      facilityAmts.push(amt);
      facilityCount++;
    }
  });
  if (facilityCount) {
    return facilityAmts;
  }
  return [total];
};

export const selectedFacilityNames = (facilities: Map<string, boolean> | undefined) => {
  if (!facilities) {
    return [];
  }
  //  const [facilities, setFacilities] = useFacilities(new Map());
  const res = [...facilities.keys()].filter((k) => facilities.get(k)).sort();
  return res;
};

export const facilityCheckedCount = (facilities: Map<string, boolean> | undefined) => {
  if (!facilities) {
    return 0;
  }
  //  const [facilities, setFacilities] = useFacilities(new Map());
  //  const facilities = useContext(FacilityContext);
  const fKeys = Object.keys(facilities);
  const checked = fKeys.reduce((a: number, c) => facilities.get(c) ? a + 1 : a, 0);
  return checked;
};
