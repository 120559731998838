import React, { Fragment, useContext, useState } from 'react';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { authContext } from 'src/contexts/AuthContext';
import { IUserListSummary, IUserPartList } from 'src/types/IList';
import API from 'src/util/API';
import { IToolbarWidgetChildProps } from 'src/widgets/Toolbar';

interface ILinkProps {
  part: string;
}

export const AddToList = ({ part, active, setActive }: ILinkProps & IToolbarWidgetChildProps) => {

  const auth = useContext(authContext);
  const [displayState, setDisplayState] = useState('new');
  const [newListName, setNewListName] = useState('');
  const [shared, setShared] = useState(false);

  const [inLists, setInLists] = useState<IUserListSummary[]>([]);
  const [outLists, setOutLists] = useState<IUserListSummary[]>([]);

  const addList = (e: React.FormEvent) => {
    const list: IUserPartList = {
      _id: 0,
      name: newListName,
      parts: [part],
      shared,
      type: 'parts',
      userIdentity: '',
    };
    API.post(auth.authToken, `/api/v1/lists`, list)
      .then((response) => {
        setDisplayState('new');
      })
      .catch((error) => {
        Alert(AlertTypes.ERROR, error.message);
      });
  };
  const addToList = (listId: number) => {
    API.post(auth.authToken, `/api/v1/lists/${listId}/part/${part}`, {})
      .then((response) => {
        setDisplayState('new');
      })
      .catch((error) => {
        Alert(AlertTypes.ERROR, error.message);
      });
  };

  const removeFromList = (listId: number) => {
    API.delete(auth.authToken, `/api/v1/lists/${listId}/part/${part}`, {})
      .then((response) => {
        setDisplayState('new');
      })
      .catch((error) => {
        Alert(AlertTypes.ERROR, error.message);
      });
  };

  if (displayState === 'new') {
    setDisplayState('loading');
    API.get(auth.authToken, `/api/v1/lists/type/parts?part=${part}&shared=false`)
      .then((response) => {
        const list: IUserListSummary[] = response.data;
        setInLists(list);
        API.get(auth.authToken, `/api/v1/lists/type/parts?shared=false`)
          .then((resp2) => {
            const outList: IUserListSummary[] = resp2.data;
            setOutLists(outList.filter((l) => list.filter((il) => il._id === l._id).length === 0));
            setDisplayState('ready');
          });
      })
      .catch((err) => {
        if (err.status === 404) {
          setInLists([]);
          setOutLists([]);
          setDisplayState('ready');
        } else {
          setDisplayState('error');
        }
      });
  }

  switch (displayState) {
    case 'new':
    case 'loading':
      if (active) {
        return <div key="list-spinner" data-uk-spinner></div>;
      }
      return <Fragment />;
    case 'ready':
    case 'edit':
      if (active) {
        return <div key="list-active" className="uk-padding-small">
          <ul className="uk-list uk-padding-top uk-marign-remove-bottom">
            {inLists.map((l) => <li key={l._id} className="uk-padding-remove uk-margin-remove">
              <a
                uk-icon="icon: minus; ratio: 0.6"
                className="uk-icon-button uk-text-danger uk-text-bold"
                onClick={() => removeFromList(l._id)} />
              {l.name} <span className="uk-text-small uk-text-muted">({l.numItems} parts)</span>
            </li>)}
            {outLists.map((l) => <li key={l._id} className="uk-padding-remove uk-margin-remove">
              <a
                uk-icon="icon: plus; ratio: 0.6"
                className="uk-icon-button uk-text-success uk-text-bold"
                onClick={() => addToList(l._id)} />
              {l.name} <span className="uk-text-small uk-text-muted">({l.numItems} parts)</span>
            </li>)}
          </ul>
          <input placeholder="new list"
            className="uk-input uk-margin-right uk-form-small uk-form-width-small"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)} />
          <label className="uk-text-small uk-margin-right "><input type="checkbox"
            className="uk-checkbox"
            checked={shared}
            onChange={() => setShared(!shared)} /> Shared</label>
          <button
            className="uk-button uk-button-small uk-button-primary uk-text-small"
            type="button"
            onClick={(e) => addList(e)}>Create List</button>
        </div>;
      } else {
        if (inLists.length) {
          return <div className="uk-text-small uk-text-muted">
            {inLists.length} list{inLists.length > 1 ? 's' : ''}
          </div>;
        } else {
          return <Fragment />;
        }
      }
    case 'error':
      return <div className="uk-text-samll">List error</div>;
  }
  return <span className="uk-text-samll"></span>;
};
