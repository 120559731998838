import * as React from 'react';

import useSelectedFacilities from 'src/hooks/selectedFacilitiesHook';
import { ISelectedFacilities } from 'src/types';

export interface ISelectedFacilitiesContextInterface {
  selectedFacilities: ISelectedFacilities;
  setSelectedFacilities: (newFacilities: ISelectedFacilities) => ISelectedFacilities;
}

export const selectedFacilityContext = React.createContext<ISelectedFacilitiesContextInterface>(
  {
    selectedFacilities: new Map(),
    setSelectedFacilities: (): ISelectedFacilities => new Map(),
  });

const { Provider } = selectedFacilityContext;

const SelectedFacilityProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const { selectedFacilities, setSelectedFacilities } = useSelectedFacilities();

  return (
    <Provider value={{ selectedFacilities, setSelectedFacilities }}>
      {children}
    </Provider>
  );
};

export default SelectedFacilityProvider;
