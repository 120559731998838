import * as React from 'react';
import { config } from '../config';

const LoginLink = (
  props: React.HTMLAttributes<HTMLImageElement>,
) => {
  return (<a href={`${config.AUTH_SERVER_URL}?callBackUrl=${window.location.origin}/loginConfirm`}>
    {props.children}
  </a>);
};

export default LoginLink;
