import queryString from 'query-string';
import React, { Fragment, useState } from 'react';
import Autosuggest, {
  ChangeEvent, RenderSuggestionParams, SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import { useHistory, useParams } from 'react-router-dom';

import { authContext } from 'src/contexts/AuthContext';

import AutosuggestTheme from 'src/assets/autosuggestTheme';
import { IUserListSummary } from 'src/types';
import API from 'src/util/API';

export interface IListSelectorProps {
  getList: (listId: number) => void;
}

const ListSelector = ({ getList }: IListSelectorProps): React.ReactElement => {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const history = useHistory();
  const params = useParams();
  const [listInput, setListInput] = useState('');
  const [listId, setListId] = useState(9);
  const [suggestions, setSuggestions] = useState<IUserListSummary[]>([]);

  // Set the part from the query string on load
  React.useEffect(() => {
    const values = queryString.parse(window.location.search);
    const queryList = values.list;
    if (queryList && authToken) {
      const newListId = +(Array.isArray(queryList) ? queryList[0] : queryList);
      if (typeof newListId === 'number' && newListId > 0) {
        getList(newListId);
        setListId(newListId);
      }
    }
  }, [params]);

  const onListSubmit = (event: React.FormEvent) => {
    if (listId && authToken) {
      history.push({
        search: `?list=${listId}`,
      });
      getList(listId);
    }
    event.preventDefault();
  };

  /*******************
  Autosuggest functions
  *******************/
  const getSuggestions = async (value: string): Promise<IUserListSummary[]> => {
    const result = await API.get(authToken, `/api/v1/lists/type/parts`);
    const lists: IUserListSummary[] = result.data;
    const matchValue = value.toLowerCase();

    const matches = lists.filter((l) => l.name.toLowerCase().startsWith(matchValue));
    return matches;
  };

  const onSuggestionsFetchRequested = async ({ value }: SuggestionsFetchRequestedParams) => {
    setSuggestions(await getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion: IUserListSummary) => {
    setListId(suggestion._id);
    return suggestion.name;
  };

  // Use your imagination to render suggestions.
  const renderSuggestionsContainer = ({ containerProps, children, query }: any) => {
    return (
      <div {...containerProps}
        className="uk-tile-default uk-list uk-margin-remove-left">
        {children}
      </div>
    );
  };
  const renderSuggestion = (suggestion: IUserListSummary, { query, isHighlighted }: RenderSuggestionParams) => {
    if (isHighlighted) {
      return (
        <span className="uk-text-small uk-text-primary uk-text-bold">
          {suggestion.name + ' (' + suggestion.numItems + ' items)'}
        </span>
      );
    }
    return (
      <span className="uk-text-small">
        {suggestion.name + ' (' + suggestion.numItems + ' items)'}
      </span>
    );
  };
  const onSuggestChange = (event: any, { newValue }: ChangeEvent) => {
    setListInput(newValue);
  };

  const thisList = suggestions.find((s) => s._id === listId);
  const inputProps = {
    className: 'uk-input uk-width-medium uk-inline',
    onChange: onSuggestChange,
    placeholder: 'Start typing to select a list',
    value: listInput,
  };

  return (
    < form onSubmit={(e) => { onListSubmit(e); }}>
      <Autosuggest id="ListID"
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={inputProps}
        theme={AutosuggestTheme}
      />
      <button type="submit" className="uk-button uk-button-primary uk-inline">Go</button>
    </form >
  );
};

export default ListSelector;
