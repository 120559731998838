
import React, { useContext, useEffect } from 'react';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { authContext } from 'src/contexts/AuthContext';
import { selectedFacilityContext } from 'src/contexts/SelectedFacilitiesContext';
import { IFacility, ISelectedFacilities } from 'src/types/IFacility';
import API from 'src/util/API';

export const FacilitySelector = (): React.ReactElement => {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const { selectedFacilities, setSelectedFacilities } = useContext(selectedFacilityContext);

  useEffect(() => {
    API.get(authToken, `api/v1/facilities`)
      .then((r) => {
        const facList: IFacility[] = r.data;
        facList.forEach((f) => selectedFacilities.set(f.facility, false));
        setSelectedFacilities(selectedFacilities);
      })
      .catch((err) => {
        Alert(AlertTypes.ERROR, 'Facilities failed to load');
      });
  }, []);

  const onFacilityChange = (event: React.FormEvent<HTMLElement>, facility: string) => {
    event.preventDefault();
    selectedFacilities.set(facility, !selectedFacilities.get(facility));
    const newFacilities: ISelectedFacilities = new Map(selectedFacilities);
    setSelectedFacilities(newFacilities);
  };

  return (
    <div>
      {[...selectedFacilities.keys()].sort().map((f) =>
        <button key={`facility ${f}`}
          className={selectedFacilities.get(f) ?
            'uk-button-small uk-button-primary uk-margin-small-left' :
            'uk-button-small uk-button-default uk-margin-small-left'}
          onClick={(e) => onFacilityChange(e, f)}>{f}</button>
      )}
    </div>
  );
};
