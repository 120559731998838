import queryString from 'query-string';
import React, { createContext, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Alert, { AlertTypes } from '../../components/AlertDisplay';
import { config } from '../../config';
import { authContext } from '../../contexts/AuthContext';
import ITask from '../../types/ITask';
import API from '../../util/API';
import { ApiError } from '../../util/ApiError';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

export interface IAdminProps {
  startPage: string;
}
export interface ITaskComponentProps {
  displayState: string;
  setTasks: (value: React.SetStateAction<ITask[]>) => void;
}

export default function TaskList(): React.ReactElement {
  return (
    <div className="uk-margin-small uk-margin-left uk-margin-right uk-margin-top uk-child-width-1-1" >
      <TaskListDetail />
    </div>
  );
}

export function TaskListDetail(): React.ReactElement {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [displayState, setDisplayState] = useState('new');
  const [tasks, setTasks] = useState<ITask[]>([]);

  if (!authToken) {
    return <div>Not Authorized</div>;
  }

  React.useEffect(() => {
    if (displayState === 'new') {
      setDisplayState('loading');
      getTasks(authToken).then((res) => {
        setTasks(res);
        setDisplayState('ready');
      })
        .catch((err) => {
          Alert(AlertTypes.ERROR, err.message);
          setTasks([]);
        });
    }
  }, []);

  if (displayState === 'new') {
    return <div>Initializing</div>;
  }
  if (displayState === 'loading') {
    return <div data-uk-spinner></div>;
  }
  if (tasks === undefined || tasks.length === 0) {
    return <div>No Tasks</div>;
  }
  return (
    <div className="uk-margin-small uk-margin-left uk-margin-right uk-child-width-1-1" >
      <div className="uk-align-left uk-grid uk-margin-left-small" >
        <h2>Tasks
        <TaskListRefresh displayState={displayState} setTasks={setTasks} />
        </h2>
        <table className="uk-table">
          <thead>
            <tr>
              <th>Task ID</th>
              <th>Name</th>
              <th>Status</th>
              <th>Start</th>
              <th>Completed</th>
              <th>Status Message</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody className="uk-table-striped">
            {tasks.map((t) => (
              <tr>
                <td>
                  <span className="uk-text-small">
                    {t.key}
                  </span>
                </td>
                <td>
                  {t.name}
                  <br />
                  <span className="uk-text-small">
                    {t.description}
                  </span>
                </td>
                <td>
                  {statusIcon(t.status)}
                </td>
                <td>
                  <span title={t.startTime && dayjs(t.startTime).format()}>
                    {t.startTime ? dayjs(t.startTime).fromNow() : ''}
                  </span>
                </td>
                <td>
                  <span title={t.completeTime && dayjs(t.completeTime).format()}>
                    {t.completeTime ? dayjs(t.completeTime).fromNow() : ''}
                  </span>
                </td>
                <td>
                  {t.statusMessage ? t.statusMessage : ''}
                </td>
                <td>
                  <span title={t.lastUpdateTime && dayjs(t.lastUpdateTime).format()}>
                    {t.lastUpdateTime ? dayjs(t.lastUpdateTime).fromNow() : ''}
                  </span>
                </td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function TaskListRefresh(state: ITaskComponentProps): React.ReactElement {

  const auth = React.useContext(authContext);
  const authToken = auth.authToken;

  if (!authToken || state.displayState === 'new') {
    return <div></div>;
  } else if (state.displayState === 'loading') {
    return <div data-uk-spinner="ratio: .3"></div>;
  }
  return (
    <div className="uk-align-right uk-grid uk-margin-left-small" >
      <a href="#" uk-icon="refresh"
        onClick={async (e) => { state.setTasks(await getTasks(authToken)); }}></a>
    </div>
  );
}

const getTasks = async (authToken: string): Promise<ITask[]> => {
  const response = await API.get(authToken, '/api/v1/tasks');
  response.data.sort((a: ITask, b: ITask) => {
    if (a.startTime === undefined) { return -1; }
    if (b.startTime === undefined) { return 1; }
    return a.startTime < b.startTime;
  });
  return response.data;
};

function statusIcon(status: string): React.ReactElement {
  switch (status) {
    case 'NEW': return <span className="uk-text-warning" uk-icon="icon: star; ratio: 1.25" />;
    case 'RUNNING': return <span className="uk-text-warning" uk-icon="icon: play; ratio: 1.5" />;
    case 'COMPLETE': return <span className="uk-text-success" uk-icon="icon: check; ratio: 1.5" />;
    case 'CANCELED': return <span className="uk-text-danger" uk-icon="icon: close; ratio: 1.5" />;
    default: return <span>{status}</span>;
  }
}
