import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import API from './util/API';

import ProtectedRoute from 'src/components/ProtectedRoute';
import AuthContextProvider from 'src/contexts/AuthContext';
import LoadOperations from 'src/pages/Admin/LoadOperations';
import LoginHistory from 'src/pages/Admin/LoginHistory';
import TaskList from 'src/pages/Admin/TaskList';
import FBForecast from 'src/pages/FBForecast';
import FBLookup from 'src/pages/FBLookup';
import FBWhereUsed from 'src/pages/FBWhereUsed';
import Index from 'src/pages/Index';
import { ListManager } from 'src/pages/ListManager';
import SerialLookup from 'src/pages/SerialLookup';
import CompleteLogin from 'src/widgets/CompleteLogin';
import PageHeader from 'src/widgets/PageHeader';
import PrimaryNav from 'src/widgets/PrimaryNav';

export default function App() {
  return (
    <AuthContextProvider>
      <div className="App">
        <BrowserRouter>
          <div >
            <PageHeader />
            <PrimaryNav />
          </div>
          <div>
            <Switch>
              <Route path="/loginConfirm" component={CompleteLogin} />
              <Route exact path="/" component={Index} />
              <ProtectedRoute path="/FBLookup" denyPath="/" component={FBLookup} />
              <ProtectedRoute path="/FBWhereUsed" denyPath="/" component={FBWhereUsed} />
              <ProtectedRoute path="/FBForecast" denyPath="/" component={FBForecast} />
              <ProtectedRoute path="/SerialLookup" denyPath="/" component={SerialLookup} />
              <ProtectedRoute path="/Admin_LoginHistory" denyPath="/" component={LoginHistory} />
              <ProtectedRoute path="/Admin_LoadOperations" denyPath="/" component={LoadOperations} />
              <ProtectedRoute path="/Admin_TaskList" denyPath="/" component={TaskList} />
              <ProtectedRoute path="/Lists" denyPath="/" component={ListManager} />
            </Switch>
          </div>
        </BrowserRouter>
      </div >
    </AuthContextProvider>
  );
}
