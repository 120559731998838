import * as Cookies from 'js-cookie';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';

export function Logout() {
  //  const [authToken, setAuthToken] = useAuthStatus('GET');
  //  console.log('Logging out; token: ' + authToken);
  const auth = React.useContext(authContext);
  const history = useHistory();

  const handleCLick = () => {
    auth.setAuthStatus('LOGOUT', undefined);
    history.push('/');
  };

  if (auth.authToken != undefined) {
    return (<a onClick={() => { handleCLick(); }}>Log Out</a>);
  } else {
    return (<a onClick={() => { return; }}></a>);
  }
}
