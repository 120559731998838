import queryString from 'query-string';
import React, { Fragment, MouseEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Alert, { AlertTypes } from '../components/AlertDisplay';
import ForecastLink from '../components/ForecastLink';
import LookupLink from '../components/LookupLink';
import { authContext } from '../contexts/AuthContext';
// import { IBomPart, IPart, IPartTypeAhead, IPartWhereUsed } from '../types/IPart';
import * as Types from '../types';
import API from '../util/API';
import PartLookup from '../widgets/PartLookup';

interface IPartProps {
  part: Types.IPart;
}
interface IPartNotFoundProps {
  loadState: Types.ILoadState;
}
interface IPartTableProps {
  parts: Types.IPartWhereUsed[];
}

export default function FBWhereUsed() {

  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [partNumber, setPartNumber] = useState('');
  const [partList, setPartList] = useState([]);
  const [loadState, setLoadState] = useState<Types.ILoadState>(Types.ILoadState.NEW);

  const getPart = (partNo: string) => {

    const values = queryString.parse(window.location.search);
    let serverLevel = values.level;
    if (serverLevel !== 'server') {
      serverLevel = 'tla';
    }

    API.get(authToken, `/api/v1/parts/${partNo}/whereUsed?serverLevel=${serverLevel}`,
      { timeout: 10000 }).then((partResponse) => {
        // patch missing mfgs.
        partResponse.data.map((p: Types.IPart) => p.manufacturer = []);
        setPartNumber(partNo);
        setLoadState(Types.ILoadState.READY);
        setPartList(partResponse.data);
      }).catch((err) => {
        setPartNumber(partNo);
        setPartList([]);
        if (err.status == 404) {
          setLoadState(Types.ILoadState.NOTFOUND);
        } else {
          Alert(AlertTypes.ERROR, err.message);
          setLoadState(Types.ILoadState.NEW);
        }
      });
  };

  return (
    <div className="uk-margin-small uk-margin-left uk-margin-right uk-child-width-1-1" >
      <h3>Part Where Used</h3>
      <div className="uk-align-left uk-grid" >
        <form>
          <div className="uk-align-left">
            <PartLookup getPart={getPart} />
          </div>
        </form>
      </div>
      {/* Data Table starts here */}
      <div className="uk-align-left uk-overflow-auto">
        <PartNotFound loadState={loadState} />
        {loadState === Types.ILoadState.LOADING &&
          <div data-uk-spinner="ratio: 3" className="uk-position-center"></div>}
        {loadState === Types.ILoadState.READY && <PartTable parts={partList} />}
      </div>
    </div>
  );
}

const PartNotFound = ({ loadState }: IPartNotFoundProps): React.ReactElement => {
  if (loadState === Types.ILoadState.NOTFOUND) {
    return (
      <div className="uk-alert uk-alert-danger">
        <h4>Part not found</h4>
      </div>);
  } else {
    return <Fragment />;
  }
};

const PartTable = ({ parts }: IPartTableProps): React.ReactElement => {
  return (
    <div>
      <span>The table below shows the quantity consumed by each of the top level parts that include it;
       intermediate parts are hidden</span>
      <table className="uk-table uk-table-divider ">
        <thead>
          <tr>
            <th>
              Total Qty
          </th>
            <th>
              Part ID
          </th>
            <th>
              Type description
          </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {parts.map((p) => {
            return (
              <tr>
                <td>{p.quantity}</td>
                <td>{p.client_part_number}</td>
                <td>{p.description}</td>
                <td>
                  <span className="uk-align-right uk-text-nowrap">
                    <ForecastLink part={p.client_part_number} />
                    <LookupLink part={p.client_part_number} />
                  </span>
                </td>
              </tr>
            );
          })
          }
        </tbody>
      </table>
    </div>
  );
};
