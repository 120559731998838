
import React from 'react';
import { Link } from 'react-router-dom';

interface ILinkProps {
  part: string;
}
export default function WhereUsedLink({ part }: ILinkProps) {
  return <Link to={`/FBWhereUsed?part=${part}`}
    className="uk-padding-remove-right uk-preserve-width"
    uk-icon="where-used" />;
}
