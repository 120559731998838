import React, { Fragment, useState } from 'react';

import { authContext } from 'src/contexts/AuthContext';

import SaveFlag, { SaveFlagMode } from 'src/components/SaveFlag';

interface ISesamePartValueProps {
  clientPartNumber: string;
  sesamePartNumber: string;
  updatePart(clientPartNumber: string, sesamePartNumber: string): Promise<void>;
}

export const SesamePartNumberEdit = ({ clientPartNumber, sesamePartNumber, updatePart }:
  ISesamePartValueProps): React.ReactElement => {

  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [partNumber, setPartNumber] = useState(sesamePartNumber);
  const [editMode, setEditMode] = useState(false);
  const [saveResult, setSaveResult] = useState<SaveFlagMode>('');

  const handleEditAction = (event: React.FormEvent) => {
    event.preventDefault();
    if (editMode && partNumber && partNumber.length > 0) {
      updatePart(clientPartNumber, partNumber)
        .then(() => { setEditMode(false); setSaveResult('saved'); })
        .catch(() => { setEditMode(true); setSaveResult('failed'); });
      setSaveResult('saving');
    } else {
      setEditMode(!editMode);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPartNumber(event.target.value);
  };

  if (editMode) {
    return (
      <div key={clientPartNumber} className="uk-text-nowrap uk-margin-remove-left">
        <form>
          <input type="text"
            key={clientPartNumber}
            placeholder="S00-000-0000"
            defaultValue={partNumber}
            onChange={(e) => { onChange(e); }} />
          <a onClick={(e) => { handleEditAction(e); }}
            className="uk-icon-link uk-margin-left uk-text-success" uk-icon="icon: check; ratio: 0.75" />
          <a onClick={(e) => { setEditMode(false); }}
            className="uk-icon-link uk-text-danger" uk-icon="icon: close; ratio: 0.75" />
          <SaveFlag saveState={saveResult} clearSaveState={setSaveResult} />
        </form>
      </div>);
  } else {
    return <div className="uk-text-nowrap uk-align-right uk-margin-remove-left" >{partNumber}
      <a onClick={(e) => { handleEditAction(e); }}
        className="uk-icon-link uk-margin-left uk-text-primary" uk-icon="icon: pencil; ratio: 0.75" />
      <SaveFlag saveState={saveResult} clearSaveState={setSaveResult} />
    </div>;
  }
};
