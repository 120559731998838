import { IPartTypeAhead } from './IPart';

export const UserListTypes = ['parts']; // more in the future
export type UserListType = (typeof UserListTypes)[number];
export const isUserListType = (x: any): x is UserListType => UserListTypes.includes(x);

export interface IUserList {
  _id: number;
  userIdentity: string;
  type: UserListType;
  shared: boolean;
  name: string;
}

export interface IUserListSummary extends IUserList {
  numItems: number;
}

export interface IUserPartList extends IUserList {
  parts: string[];
}

export interface IUserPartListDetail extends IUserList {
  parts: IPartTypeAhead[];
}
