import queryString from 'query-string';
import React, { Fragment, useState, useRef } from 'react';
import Autosuggest, {
  ChangeEvent, RenderSuggestionParams, SuggestionsFetchRequestedParams,
} from 'react-autosuggest';
import { useHistory, useParams } from 'react-router-dom';

import { authContext } from '../contexts/AuthContext';

import AutosuggestTheme from '../assets/autosuggestTheme';
import Scanner from '../components/Scanner';
import { IBomPart, IPart, IPartTypeAhead } from '../types/IPart';
import API from '../util/API';

export interface IPartLookupProps {
  getPart: (partNumber: string) => void;
}

const PartLookup = ({ getPart }: IPartLookupProps): React.ReactElement => {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const history = useHistory();
  const params = useParams();
  const [partNumber, setPartNumber] = useState('');
  const [suggestions, setSuggestions] = useState<IPartTypeAhead[]>([]);
  const [scanning, setScanning] = useState(false);
  const scannerRef = useRef(null);

  // Set the part from the query string on load
  React.useEffect(() => {
    const values = queryString.parse(window.location.search);
    const queryPart = values.part;
    if (queryPart && authToken) {
      const newPart = Array.isArray(queryPart) ? queryPart[0] : queryPart;
      console.log('calling getPart in useEffect');
      getPart(newPart);
      setPartNumber(newPart);
    }
  }, []);

  /* hide the injected video frame when scanning is off */
  React.useEffect(() => {
    if (document) {
      const elp = document.getElementById('scannerbox') as HTMLDivElement;
      if (elp) {
        const el = elp.firstElementChild;
        if (el && el.nodeName === 'VIDEO') {
          const elv = el as HTMLVideoElement;
          elv.style.height = scanning ? 'auto' : '1px';
        }
      }
    }
  }, [scanning]);

  const onScanResult = (scanResult: string) => {
    if (scanResult && authToken) {
      history.push({
        search: `?part=${scanResult}`,
      });
      setScanning(false);
      setPartNumber(scanResult);
      getPart(scanResult);
    }
  }

  const onScanButtonClick = (event: React.FormEvent) => {
    if (!scanning) {
      setPartNumber('');
    }
    setScanning(!scanning);

  }
  const onPartSubmit = (event: React.FormEvent) => {
    console.log('onPartSumbit called');
    if (partNumber && authToken) {
      history.push({
        search: `?part=${partNumber}`,
      });
      setScanning(false);
      getPart(partNumber);
    }
    event.preventDefault();
  };

  /*******************
  Autosuggest functions
  *******************/
  const getSuggestions = async (value: string): Promise<IPartTypeAhead[]> => {
    const partList = await API.get(authToken, `/api/v1/parts/typeAhead/${value}`);
    const result = partList.data;
    return result;
  };

  const onSuggestionsFetchRequested = async ({ value }: SuggestionsFetchRequestedParams) => {
    setSuggestions(await getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion: IPartTypeAhead) => {
    return suggestion.client_part_number;
  };

  // Use your imagination to render suggestions.
  const renderSuggestionsContainer = ({ containerProps, children, query }: any) => {
    return (
      <div {...containerProps}
        className="uk-tile-default uk-list uk-margin-remove-left">
        {children}
      </div>
    );
  };
  const renderSuggestion = (suggestion: IPartTypeAhead, { query, isHighlighted }: RenderSuggestionParams) => {
    if (isHighlighted) {
      return (
        <span className="uk-text-small uk-text-primary uk-text-bold">
          {suggestion.client_part_number + ': ' + suggestion.description}
        </span>
      );
    }
    return (
      <span className="uk-text-small">
        {suggestion.client_part_number + ': ' + suggestion.description}
      </span>
    );
  };
  const onSuggestChange = (event: any, { newValue }: ChangeEvent) => {
    setPartNumber(newValue);
  };
  const inputProps = {
    className: 'uk-input uk-width-medium uk-inline',
    onChange: onSuggestChange,
    placeholder: 'Enter a part number or description',
    value: partNumber,
  };

  return (
    < form onSubmit={(e) => { onPartSubmit(e); }}>
      <Autosuggest id="PartNumber"
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={inputProps}
        theme={AutosuggestTheme}
      />
      <button type="submit" className="uk-button uk-button-primary uk-inline">Go</button>
      <button className="uk-button uk-button-secondary uk-inline"
        onClick={(e) => onScanButtonClick(e)} > {scanning ? 'Stop' : 'Scan'}</button>
      <div id="scannerbox"
        ref={scannerRef}
        style={{ visibility: scanning ? 'visible' : 'hidden', position: 'relative', border: '3px solid gray' }}>
        {/* <video style={{ width: window.innerWidth, height: 480, border: '3px solid orange' }}/> */}
        {scanning ?
          <canvas className="drawingBuffer" style={{
            position: 'absolute',
            top: '0px',
            // left: '0px',
            // height: '40px',
            // width: '100px',
            // border: '3px solid green',
          }} width="640" height="480" />
          : null}
        {scanning ?
          < Scanner
            scannerRef={scannerRef}
            onDetected={(result) => onScanResult(result)}
          />
          : null}
      </div>
    </form >
  );
};

export default PartLookup;
