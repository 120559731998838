import queryString from 'query-string';
import React, { useState } from 'react';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import { authContext } from 'src/contexts/AuthContext';
import SelectedFacilityProvider from 'src/contexts/SelectedFacilitiesContext';
import { IPartForecastQuantities, IPartWhereUsed } from 'src/types/IPart';
import { ILoadState } from 'src/types/IUtil';
import API from 'src/util/API';
import ListSelector from 'src/widgets/ListSelector';
import PartLookup from 'src/widgets/PartLookup';
import { FacilitySelector } from './FBForecast/FacilitySelector';
import { ForecastTable } from './FBForecast/ForecastTable';
import { IUserPartListDetail } from 'src/types';

export default function FBForecast() {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;

  const [targetPartList, setTargetPartList] = useState(['']);
  const [componentPartList, setComponentPartList] = useState<IPartForecastQuantities[]>([]);
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);
  const [showZero, setShowZero] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  if (!authToken) {
    return <div><h1>Not Authorized</h1></div>;
  }

  const getComponentParts = async (partNo: string): Promise<IPartForecastQuantities[]> => {
    const values = queryString.parse(window.location.search);
    const serverLevel = values.level === 'tla' ? 'tla' : 'server';
    const partList = await API.get(authToken, `/api/v1/parts/${partNo}/whereUsed?serverLevel=${serverLevel}`, { timeout: 8500 })
      .catch((err) => {
        if (err.status === 404) {
          setDisplayState(ILoadState.NOTFOUND);
          return;
        }
        setDisplayState(ILoadState.NEW);
        Alert(AlertTypes.ERROR, err.message);
      });

    const componentList = partList.data
      .filter((p: IPartWhereUsed) => p.quantity > 0)
      .map((p: IPartWhereUsed) => {
        return {
          _id: p._id,
          client_part_number: p.client_part_number,
          sesame_part_number: p.sesame_part_number,
          description: p.sesame_part_number,
          bomQuantities: { [partNo]: p.quantity },
        }
      }) as IPartForecastQuantities[];
    return componentList;
  }

  const getPart = async (partNo: string) => {
    setTargetPartList([partNo]);
    getComponentParts(partNo)
      .then((parts) => {
        setComponentPartList(parts.sort((a, b) => a.client_part_number < b.client_part_number ? -1 : 1));
        setDisplayState(ILoadState.READY);
      });
    setDisplayState(ILoadState.LOADING);
  };

  const getList = (listId: number) => {
    API.get(authToken, `api/v1/lists/${listId}`).then(async (typeResponse) => {
      const listParts: IUserPartListDetail = typeResponse.data;
      const fullPartList: IPartForecastQuantities[] = [];
      for (const lp of listParts.parts) {
        const componentParts = await getComponentParts(lp.client_part_number);
        for (const cp of componentParts) {
          const index = fullPartList.findIndex((p) => cp.client_part_number === p.client_part_number);
          if (index >= 0) {
            fullPartList[index].bomQuantities[lp.client_part_number] = cp.bomQuantities[lp.client_part_number];
          } else {
            fullPartList.push(cp);
          }
        }
      }
      setTargetPartList(listParts.parts.map((p) => p.client_part_number))
      setComponentPartList(fullPartList);
      setDisplayState(ILoadState.READY);
    });
    setDisplayState(ILoadState.LOADING);
  };

  return (
    <SelectedFacilityProvider>
      <div className="uk-margin-small uk-margin-left uk-margin-right uk-child-width-1-1" >
        <h3>Inventory Forecast</h3>
        <div className="uk-align-left uk-grid" >
          <div className="uk-align-left">
            <PartLookup getPart={getPart} />
            OR
                            <ListSelector getList={getList} />
          </div>
          <div className="uk-align-left">
            <FacilitySelector />
            <br />
            <div className="uk-margin-small-top">
              <button
                className={showDetail ?
                  'uk-button-small uk-button-primary uk-margin-small-left' :
                  'uk-button-small uk-button-default uk-margin-small-left'}
                onClick={(e) => { e.preventDefault(); setShowDetail(!showDetail); }}>
                Show Extended
             </button>
              <button
                className={showZero ?
                  'uk-button-small uk-button-primary uk-margin-small-left' :
                  'uk-button-small uk-button-default uk-margin-small-left'}
                onClick={(e) => { e.preventDefault(); setShowZero(!showZero); }}>
                Include 0 items
              </button>
            </div>
          </div>
        </div>
        <div className="uk-align-left uk-overflow-auto">
          {displayState === ILoadState.LOADING && <div data-uk-spinner="ratio: 3" className="uk-position-center"></div>}
          {displayState === ILoadState.NOTFOUND &&
            <div className="uk-alert uk-alert-danger"><h3>Part not found or no forecast data exists</h3></div>
          }
          {/* Data Table starts here */}
          {displayState === ILoadState.READY &&
            <div>
              {
                componentPartList &&
                <ForecastTable
                  targetPartList={targetPartList}
                  componentPartList={componentPartList}
                  showZero={showZero}
                  showDetail={showDetail} />
              }
            </div>
          }
        </div>
      </div>
    </SelectedFacilityProvider>
  );
}
