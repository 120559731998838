import * as React from 'react';

// display a drop down notification from the top
export enum AlertTypes {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

// the intent here is not to allow clients to override this but to allow
// more flexibility
const AlertStyles = {
  ERROR: 'danger',
  INFO: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const Alert = (alertStyle: AlertTypes, message: string) => {

  (window as any).UIkit.notification(message, { status: AlertStyles[alertStyle] });
  //  return (<div>Alert: style</div>);
};

export default Alert;
