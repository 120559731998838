import * as React from 'react';
import { Link, Redirect, Route } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';

interface IProtectedProps {
  component: any;
  denyPath: string;
  path: string;
}

function ProtectedRoute(
  { denyPath: denyPath,
    component: Component, ...rest }: IProtectedProps) {

  const auth = React.useContext(authContext);

  // authCheckFn must be a hook or a deterministic, unconditional wrapper on that hook..
  const isLoggedIn = auth.authToken != undefined;
  return (<Route {...rest} render={(props) => (
    isLoggedIn ?
      <Component {...props} /> : <Redirect to={{ pathname: denyPath, state: { from: props.location } }} />
  )} />
  );
}

export default ProtectedRoute;
