import queryString from 'query-string';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Alert, { AlertTypes } from '../../components/AlertDisplay';
import { config } from '../../config';
import { authContext } from '../../contexts/AuthContext';
import API from '../../util/API';
import { ApiError } from '../../util/ApiError';

export interface IAdminProps {
  startPage: string;
}

export default function LoginHistory(): React.ReactElement {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;

  return (
    <div className="uk-margin-small uk-margin-left uk-margin-right uk-child-width-1-1" >
      <div className="uk-align-left uk-grid" >
        Login History
      </div>
    </div>
  );
}

const TaskList = (): React.ReactElement => {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;

  return (
    <div className="uk-margin-small uk-margin-left uk-margin-right uk-child-width-1-1" >
      <div className="uk-align-left uk-grid" >
        TaskHistory
      </div>
    </div>
  );
};
