import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Logout } from 'src/components/Logout';
import SesameLogin from 'src/components/SesameLogin';
import { authContext } from 'src/contexts/AuthContext';
import { IProfile } from 'src/types';
import { hideDropDown } from 'src/util/UIUtil';

import { loadProfile } from '../util/ProfileUtil';

const UserProfile = (props: React.HTMLAttributes<HTMLElement>) => {
  //  const [profile, setProfile] = useState({});
  const auth = React.useContext(authContext);
  if (auth.authToken && !auth.profile) {
    loadProfile(auth.authToken, auth);
  }
  const userName = auth.profile ? auth.profile.displayName || 'No Name' : 'No Profile';

  return (
    <SesameLogin>
      <div>
        <a >{userName}</a>
        <ul id="profileList" className="uk-background-secondary uk-light uk-list" uk-dropdown="mode: click">
          <li>
            <Link to="/Lists" onClick={() => hideDropDown('profileList')}>Lists</Link>
          </li>
          <li />
          <li>
            <Logout />
          </li>
        </ul>
      </div>
    </SesameLogin>
  );
};

export default UserProfile;
