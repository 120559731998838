import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Alert, { AlertTypes } from '../../components/AlertDisplay';
import { config } from '../../config';
import { authContext } from '../../contexts/AuthContext';
import API from '../../util/API';
import { ApiError } from '../../util/ApiError';

interface ILoadOperation {
  buttonText: string;
  helpText: string;
  url: string;
}

interface ILoadFnProps {
  authToken: string;
  operation: ILoadOperation;
}

export default function LoadOperations(): React.ReactElement {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;

  if (!authToken) {
    return <div>Not Authorized</div>;
  }

  const operations: ILoadOperation[] = [
    {
      buttonText: 'Load Dcom Data',
      helpText: 'Loads Facebook Dcom data from the ETL table',
      url: '/api/v1/admin/data/loadDcom',
    },
    {
      buttonText: 'Load Asset Map',
      helpText: 'Loads mapping data to link FB parts to physical assets from the asset ETL table',
      url: '/api/v1/admin/data/loadAssetMap',
    },
    {
      buttonText: 'Update Part Numbers',
      helpText: 'Assigns FB Part Numbers to dcom data from the asset map',
      url: '/api/v1/admin/data/applyAssetMap',
    },
  ];
  return (
    <div className="uk-margin-small uk-margin-left uk-margin-right uk-margin-top uk-child-width-1-1" >
      <h2>Select an operation to start.</h2>
      <p>ETL Loading must be done beforehand.  Check the task monitor for progress</p>
      <div>
        {operations.map((o) => <OperationButton authToken={authToken} operation={o} />)}
      </div>
    </div>
  );
}

export function OperationButton({ authToken, operation }: ILoadFnProps): React.ReactElement {
  const history = useHistory();
  return (
    <div>
      <button className="uk-margin-small uk-button uk-button-primary"
        onClick={(e) => { startLoadOp(authToken, history, operation) }}>{operation.buttonText}</button>
      <span className="uk-text-small uk-margin-left">
        {operation.helpText}
      </span>
      <br />
    </div>
  );
}

const startLoadOp = async (authToken: string, history: any, operation: ILoadOperation) => {
  const response = await API.get(authToken, operation.url);
  if (response.status === 200) {
    history.push('/Admin_TaskList');
  }
};
