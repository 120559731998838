import * as React from 'react';

export type SaveFlagMode = 'saving' | 'saved' | 'failed' | '';
export const FADE_TIME = 5000;

interface ISaveFlagProps {
  saveState: SaveFlagMode;
  clearSaveState: (newState: SaveFlagMode) => void;
}

const SaveFlag = ({ saveState, clearSaveState }: ISaveFlagProps) => {
  if (saveState !== '') {
    setTimeout(() => clearSaveState(''), FADE_TIME);
  }
  switch (saveState) {
    case 'saving':
      return <span className="uk-text-primary"> <br /> saving </span>;
    case 'saved':
      return <span className="uk-text-success uk-animation-fade uk-animation-reverse"> <br /> saved </span>;
    case 'failed':
      return <span className="uk-text-danger uk-animation-fade uk-animation-reverse"> <br /> failed </span>;
  }
  return <span />;
};
export default SaveFlag;
