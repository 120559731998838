import queryString from 'query-string';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Alert, { AlertTypes } from '../components/AlertDisplay';
import { authContext } from '../contexts/AuthContext';
import API from '../util/API';
import { loadProfile } from '../util/ProfileUtil';

function CompleteLogin() {
  const auth = React.useContext(authContext);
  const history = useHistory();
  const values = queryString.parse(window.location.search);
  const code = values.code;
  let bearerToken = '';

  if (!code) {
    return (<div>No confirmation code provided</div>);
  }
  API.post(auth.authToken, '/api/v1/auth/redeemToken', {
    challengeKey: code,
  }).then((response) => {
    // if/when we get expires from the server set that here.
    bearerToken = response.data.bearerToken;
    auth.setAuthStatus('SET', bearerToken);
    loadProfile(bearerToken, auth);
  }).catch((error) => {
    //    (window as any).UIkit.notification(error.message, { status: 'danger' });
    //    return (<div>Oops!</div>);
    return Alert(AlertTypes.ERROR, error.message);
  });
  history.push('/');
  return (<div className="uk-card uk-card-body uk-aligh-center">Log in successful</div>);

}

export default CompleteLogin;
