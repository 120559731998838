
import queryString from 'query-string';
import React, { Fragment, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import ForecastLink from 'src/components/ForecastLink';
import LookupLink from 'src/components/LookupLink';
import WhereUsedLink from 'src/components/WhereUsedLink';
import { authContext } from 'src/contexts/AuthContext';
import {
  IBomPart, ILoadState, IPart, IPartTypeAhead,
  IUserList, IUserPartList, IUserPartListDetail
} from 'src/types';
import API from 'src/util/API';
import { AddToList } from 'src/widgets/AddToList';
import ListSelector from 'src/widgets/ListSelector';
import PartLookup from 'src/widgets/PartLookup';
import { SesamePartNumberEdit } from 'src/widgets/SesamePartNumberEdit';
import { Toolbar, ToolbarContainer, ToolbarWidget } from 'src/widgets/Toolbar';

interface IPartProps {
  part: IPart;
}
interface IPartListProps {
  partList: IPartTypeAhead[];
  searchText: string;
}

interface IBomPartProps {
  bomPart: IBomPart | 'NotFound' | undefined;
  level: number;
}
interface IBomComponentProps {
  components: IBomPart[];
  level: number;
}

const PAGE_SIZE = 100;

const usePart = () => {
  return useState<IBomPart | 'NotFound' | undefined>(undefined);
};

export default function FBLookup() {

  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [part, setPart] = usePart();
  const [partList, setPartList] = useState<IPartTypeAhead[]>([]);
  const [pageMode, setPageMode] = useState<'bom' | 'list' | 'noresults'>('bom');
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW);
  const [searchText, setSearchText] = useState('');

  const queryValues = queryString.parse(window.location.search);
  const queryMode = queryValues.mode;

  const getPart = (partNumber: string) => {
    API.get(authToken, `api/v1/parts/typeAhead/${partNumber}`)
      .then((typeResponse) => {
        const results: IPartTypeAhead[] = typeResponse.data;
        const foundIndex = results.findIndex((r) => r.client_part_number === partNumber);
        console.log('foundIndex: ' + foundIndex + ' result length: ' + results.length);

        if (foundIndex >= 0 || results.length === 1) { // was queryMode='bom'
          console.log('Fetching part ' + results[Math.max(0, foundIndex)].client_part_number);
          API.get(authToken, `/api/v1/parts/${results[Math.max(0, foundIndex)].client_part_number}?bom=assembly`,
            { timeout: 3500, skipQueue: true })
            .then((partResponse) => {
              setPart(partResponse.data);
              setDisplayState(ILoadState.READY);
              setPageMode('bom');
              console.log('setting page to bom mode');
              return;
            })
            .catch(err => {
              Alert(AlertTypes.ERROR, err.message);
              setDisplayState(ILoadState.ERROR);
              return;
            });
        } else {
          console.log(' looking for part ' + partNumber);
          API.get(authToken, `/api/v1/parts/search/${partNumber}`,
            { timeout: 3500, skipQueue: true })
            .then((searchResponse) => {
              if (!searchResponse.data.length) {
                setDisplayState(ILoadState.READY);
                setPageMode('noresults');
                console.log('setting page to noresults');
                return;
              } else if (searchResponse.data.length === 1) {
                API.get(authToken, `/api/v1/parts/${searchResponse.data[0].client_part_number}?bom=assembly`,
                  { timeout: 3500, skipQueue: true })
                  .then((partResponse) => {
                    setPart(partResponse.data);
                    setDisplayState(ILoadState.READY);
                    setPageMode('bom');
                    console.log('setting page to bom mode (assembly)');
                    return;
                  })
                  .catch(err => {
                    Alert(AlertTypes.ERROR, err.message);
                    setDisplayState(ILoadState.ERROR);
                  });
              } else {
                setPartList(searchResponse.data);
                setDisplayState(ILoadState.READY);
                setSearchText(partNumber);
                setPageMode('list');
                console.log('setting page to list mode');
                return;
              }
            })
            .catch(err => {
              setDisplayState(ILoadState.NEW);
              Alert(AlertTypes.ERROR, err.message);
              return;
            });
        };
      }).catch((err) => {
        setDisplayState(ILoadState.READY);
        if (err.status == 404) {
          setPageMode('noresults');
        } else {
          Alert(AlertTypes.ERROR, err.message);
          setPart(undefined);
        }
        return;
      });
    setDisplayState(ILoadState.LOADING);
  };

  const getList = (listId: number) => {
    API.get(authToken, `api/v1/lists/${listId}`)
      .then((typeResponse) => {
        const result: IUserPartListDetail = typeResponse.data;
        setPageMode('list');
        setPartList(result.parts);
        setSearchText('List: ' + result.name);
        setDisplayState(ILoadState.READY);
      })
      .catch(err => {
        setDisplayState(ILoadState.READY);
        if (err.status == 404) {
          setPageMode('noresults');
        } else {
          Alert(AlertTypes.ERROR, err.message);
          setPart(undefined);
        }
        return;
      });
    setDisplayState(ILoadState.LOADING);
  };

  return (
    <div className="uk-margin-small uk-margin-left uk-margin-right uk-child-width-1-1" >
      <h3>Part Lookup</h3>
      <div className="uk-inline uk-margin-bottom" >
        <PartLookup getPart={getPart} />
        OR
          <ListSelector getList={getList} />
      </div>
      {/* Data Table starts here */}
      {displayState === ILoadState.LOADING && <div data-uk-spinner="ratio: 3" className="uk-position-center"></div>}
      {displayState === ILoadState.READY &&
        <div className="uk-align-left uk-overflow-auto">
          {pageMode === 'noresults' && <PartNotFound />}
          {pageMode === 'bom' && <PartTable bomPart={part} level={0} />}
          {pageMode === 'list' && <PartList partList={partList} searchText={searchText} />}
        </div>
      }
    </div>
  );
}

const PartNotFound = (): React.ReactElement => {
  return (
    <div className="uk-alert uk-alert-danger">
      <h4>No Results</h4>
    </div>);
};

const saveSesamePartNumber = async (authToken: string, clientPartNumber: string, sesamePartNumber: string):
  Promise<void> => {

  const partResponse = await API.get(authToken,
    `/api/v1/parts/${clientPartNumber}?bom=part`, { timeout: 2500, skipQueue: true });

  const part = partResponse.data;
  part.sesame_part_number = sesamePartNumber;
  return API.patch(authToken, '/api/v1/parts', part);
};

const PartList = ({ partList, searchText }: IPartListProps): React.ReactElement => {
  const auth = React.useContext(authContext);
  const [scrollCount, setScrollCount] = useState(partList.length > PAGE_SIZE ? PAGE_SIZE : partList.length);
  const authToken = auth.authToken || '';

  const sorted = partList.sort((a, b) => a.client_part_number < b.client_part_number ? -1 : 1).slice(0, scrollCount);
  return (
    <>
      <div><h3>Results for <span className="uk-text-primary">{searchText}</span></h3></div>
      <table className="uk-table uk-table-divider uk-alternating">
        <thead>
          <tr key="header">
            <th>FB Part Number</th>
            <th>Sesame Part</th>
            <th>Description</th>
            <th />
          </tr>
        </thead>
        <InfiniteScroll
          dataLength={scrollCount}
          next={() => setScrollCount(Math.min(scrollCount + PAGE_SIZE, partList.length))}
          hasMore={scrollCount < partList.length}
          loader={<tr><td colSpan={3} data-uk-spinner /></tr>}
          endMessage={<h4 className="uk-align-center">...</h4>}
        >
          {sorted.map((p) => (
            <tr key={p.client_part_number}>
              <td>{p.client_part_number}</td>
              <td><SesamePartNumberEdit
                clientPartNumber={p.client_part_number}
                sesamePartNumber={p.sesame_part_number}
                updatePart={
                  (clientPartNumber, sesamePartNumber): Promise<void> =>
                    saveSesamePartNumber(authToken, clientPartNumber, sesamePartNumber)}
              />
              </td>
              <td>
                {p.description}
                <ToolbarContainer>
                  <Toolbar >
                    <a id={`list-${p.client_part_number}`} uk-icon="list" />
                    <LookupLink part={p.client_part_number} />
                    <ForecastLink part={p.client_part_number} />
                    <WhereUsedLink part={p.client_part_number} />
                  </Toolbar>
                  <ToolbarWidget widget={`list-${p.client_part_number}`} >
                    <AddToList part={p.client_part_number}
                      active={false} setActive={() => { }} />
                  </ToolbarWidget>
                </ToolbarContainer>
              </td>
            </tr>
          ))}
        </InfiniteScroll>
      </table>
    </>
  );
};

const PartTable = ({ bomPart }: IBomPartProps): React.ReactElement => {
  if (!bomPart || bomPart === 'NotFound') {
    return (<React.Fragment />);
  }
  return (
    <>
      <div><h3>Assembly Details (BOM)</h3></div>
      <table className="uk-table uk-table-divider ">
        <thead>
          <tr>
            <th>
              Part ID
            </th>
            <th>
              Sesame Part
            </th>
            <th>
              Qty
            </th>
            <th>
              Type description
          </th>
          </tr>
        </thead>
        <tbody>
          <PartRender bomPart={bomPart} level={0} />
        </tbody>
      </table>
    </>
  );
};

function BomComponentsRender({ components, level }: IBomComponentProps): React.ReactElement {
  if (components) {
    return <React.Fragment>
      {components.map((c) => <PartRender bomPart={c} key={'bom:' + c.part._id} level={level} />)}
    </React.Fragment>;
  } else {
    return <React.Fragment />;
  }
}

function PartRender({ bomPart, level }: IBomPartProps): React.ReactElement {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken || '';

  const [mfg, setMfg] = useState('');

  if (bomPart && bomPart !== 'NotFound') {
    return (
      <React.Fragment>
        <tr key={bomPart.part.client_part_number}>
          <td style={{ whiteSpace: 'nowrap' }}>
            {'\x0A\x0A\u2219\x0A\x0A'.repeat(level) + bomPart.part.client_part_number}
          </td>
          <td>
            <SesamePartNumberEdit
              clientPartNumber={bomPart.part.client_part_number}
              sesamePartNumber={bomPart.part.sesame_part_number || ''}
              updatePart={
                (clientPartNumber, sesamePartNumber): Promise<void> =>
                  saveSesamePartNumber(authToken, clientPartNumber, sesamePartNumber)}
            />
          </td>
          <td >
            {bomPart.quantity}
          </td>
          <td >
            {bomPart.part.description}
            <ToolbarContainer>
              <Toolbar >
                <a id={`mfg-${bomPart.part.client_part_number}`} uk-icon="nut" />
                <a id={`list-${bomPart.part.client_part_number}`} uk-icon="list" />
                <LookupLink part={bomPart.part.client_part_number} />
                <ForecastLink part={bomPart.part.client_part_number} />
                <WhereUsedLink part={bomPart.part.client_part_number} />
              </Toolbar>
              <ToolbarWidget widget={`mfg-${bomPart.part.client_part_number}`}>
                <ManufacturerEdit part={bomPart.part}
                  active={false} setActive={() => { }} />
              </ToolbarWidget>
              <ToolbarWidget widget={`list-${bomPart.part.client_part_number}`}>
                <AddToList part={bomPart.part.client_part_number}
                  active={false} setActive={() => { }} />
              </ToolbarWidget>
            </ToolbarContainer>
          </td>
        </tr>
        <BomComponentsRender components={bomPart.components.sort(
          (a, b) => a.part.client_part_number < b.part.client_part_number ? -1 : 1)}
          level={level + 1} />
      </React.Fragment>
    );
  } else {
    return (
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }
}

interface IManufacturer {
  manufacturer: string;
  part_no: string;
}

function ManufacturerEdit({ part, active, setActive }:
  IPartProps & { setActive: () => void, active: boolean }): React.ReactElement {

  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [displayState, setDisplayState] = useState('new');
  const [existMfg, setExistMfg] = useState<IManufacturer | undefined>(undefined);
  const [mfgName, setMfgName] = useState(existMfg ? existMfg.manufacturer : undefined);
  const [mfgPart, setMfgPart] = useState(existMfg ? existMfg.part_no : undefined);

  if (displayState === 'new') {
    setDisplayState('loading');
    API.get(authToken, `/api/v1/parts/${part.client_part_number}?bom=part`)
      .then((response) => {
        const thisPart: IPart = response.data;
        if (thisPart.manufacturer) {
          setExistMfg(thisPart.manufacturer[0]);
          setMfgName(thisPart.manufacturer[0].manufacturer);
          setMfgPart(thisPart.manufacturer[0].part_no);
        }
      });
    setDisplayState('ready'); // was ready
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfgName(event.target.value);
  };
  const onPartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfgPart(event.target.value);
  };

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!existMfg || mfgName !== existMfg.manufacturer) {
      // we should really check if it exists first..
      await API.post(authToken, '/api/v1/manufacturers', { _id: 0, name: mfgName })
        .catch((err) => {
          console.log(JSON.stringify(err));
        });
    }
    if (existMfg) {
      await API.delete(authToken, `/api/v1/parts/${part.client_part_number}/manufacturerPart`, existMfg)
        .then(async (r) => {
          await API.post(authToken, `/api/v1/parts/${part.client_part_number}/manufacturerPart`,
            { manufacturer: mfgName, part_no: mfgPart });
        })
        .catch((err) => {
          Alert(AlertTypes.ERROR, 'Failed to create part');
        })
        .finally(() => {
          setDisplayState('new');
          setActive();
        });
    } else {
      await API.post(authToken, `/api/v1/parts/${part.client_part_number}/manufacturerPart`,
        { manufacturer: mfgName, part_no: mfgPart })
        .catch((err) => {
          Alert(AlertTypes.ERROR, 'Failed to create part');
        });
    }
    setDisplayState('new');
  };

  let mfg = '';
  let partNo = '';
  if (part.manufacturer && part.manufacturer.length > 0) {
    mfg = part.manufacturer[0].manufacturer;
    partNo = part.manufacturer[0].part_no;
  }

  switch (displayState) {
    case 'new':
    case 'loading':
      return (<div data-uk-spinner></div>);
    case 'edit':
    case 'ready':
      if (active) {
        return <Fragment>
          <form className="uk-padding-small">
            <span className="uk-text-italic uk-text-small uk-text-secondary">
              <input onChange={(e) => { onNameChange(e); }}
                placeholder="Manufacturer" className="uk-margin-right" size={18} defaultValue={mfgName} />
              <input onChange={(e) => { onPartChange(e); }}
                placeholder="Part Number" size={18} defaultValue={mfgPart} />
            </span>
            <a onClick={(e) => { handleSave(e); }}
              className="uk-icon-link uk-margin-left uk-text-success " uk-icon="check" />
          </form>
        </Fragment >;
      } else {
        if (mfgName || mfgPart) {
          return <div className="uk-text-small">{mfgName}: {mfgPart}</div>;
        }
        return <div />
      }
    default: return <span className="uk-text-small">{mfgName}: {mfgPart}</span>;
  }
}
