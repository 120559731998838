import * as React from 'react';

import sesameLogo from '../assets/images/sesame-logo.svg';

import SVG from 'react-inlinesvg';

const SesameLogo = (props: React.HTMLAttributes<HTMLImageElement>) => (
  <SVG src={sesameLogo} alt="Sesame Logo" width="240px"
    classame="uk-margin-left uk-margin-botton" />
);

export default SesameLogo;
