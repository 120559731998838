import queryString from 'query-string';
import React, { Fragment, MouseEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Alert, { AlertTypes } from 'src/components/AlertDisplay';
import SaveFlag, { SaveFlagMode } from 'src/components/SaveFlag';
import { authContext } from 'src/contexts/AuthContext';
import { ILoadState, IUserListSummary } from 'src/types';
import API from 'src/util/API';

const FADE_TIME = 2500;

interface IListRowProps {
  list: IUserListSummary;
  signalUpdate: () => void;
}

export function ListManager() {

  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [loadState, setLoadState] = useState<ILoadState>(ILoadState.NEW);
  const [lists, setLists] = useState<IUserListSummary[]>([]);
  const history = useHistory();

  const getUserLists = () => {

    API.get(authToken, `/api/v1/lists/type/parts?shared=false`,
      { timeout: 10000 }).then((listResponse) => {
        const rawLists: IUserListSummary[] = listResponse.data;
        setLoadState(ILoadState.READY);
        setLists(rawLists.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
      }).catch((err) => {
        if (err.status == 404) {
          setLoadState(ILoadState.NOTFOUND);
        } else {
          Alert(AlertTypes.ERROR, err.message);
          setLoadState(ILoadState.NEW);
        }
      });
    setLoadState(ILoadState.LOADING);
  };

  React.useEffect(() => {
    getUserLists();
  }, []);

  if (loadState === ILoadState.NEW || loadState === ILoadState.LOADING) {
    return <div data-uk-spinner />;
  }
  if (loadState === ILoadState.NOTFOUND) {
    return <h3>No Lists</h3>;
  }
  return (
    <div className="uk-margin-small uk-margin-top uk-margin-left uk-margin-right uk-child-width-1-1" >
      <h3>Your Lists</h3>
      <table className="uk-table uk-table-divider uk-alternating">
        <thead>
          <tr key="header" className="uk-table-header">
            <td>Name</td>
            <td>Shared</td>
            <td>Parts</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {lists.map((l) => <ListRow list={l} signalUpdate={getUserLists} />)}
        </tbody>
      </table>
    </div>
  );
}

const ListRow = ({ list, signalUpdate }: IListRowProps) => {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  const [editMode, setEditMode] = useState(false);
  const [listName, setListName] = useState(list.name);
  const [listShared, setListShared] = useState(list.shared);
  const [saveResult, setSaveResult] = useState<SaveFlagMode>('');

  const onSave = () => {
    list.name = listName;
    list.shared = listShared;
    API.patch(authToken, `/api/v1/lists/${list._id}`, list)
      .then(() => { setEditMode(false); setSaveResult('saved'); signalUpdate(); })
      .catch(() => { setEditMode(true); setSaveResult('failed'); });
    setSaveResult('saving');
  };

  const onDelete = () => {
    if ((window as any).confirm(`Are you sure you want to delete "${list.name}"?`)) {
      API.delete(authToken, `/api/v1/lists/${list._id}`, {})
        .then(() => { setSaveResult('saved'); signalUpdate(); })
        .catch(() => setSaveResult('failed'));
    }
  };

  if (editMode) {
    return <tr key={list._id}>
      <td>
        <input
          type="input"
          className="uk-input"
          value={listName}
          onChange={(e) => setListName(e.target.value)} />
      </td>
      <td>
        <input
          type="checkbox"
          className="uk-checkbox"
          checked={listShared}
          onChange={(e) => setListShared(e.target.checked)} /></td>
      <td>{list.numItems}</td>
      <td>
        <span className="uk-preserve-width uk-margin-right uk-text-success" uk-icon="check"
          onClick={() => onSave()} />
        <span className="uk-preserve-width uk-margin-right uk-text-danger" uk-icon="close"
          onClick={() => setEditMode(false)} />
      </td>
    </tr>;
  }
  return <tr key={list._id}>
    <td>
      {list.name}
      <SaveFlag saveState={saveResult} clearSaveState={setSaveResult} />
    </td>
    {list.shared ?
      <td uk-icon="users" />
      :
      <td />
    }
    <td>{list.numItems}</td>
    <td><span className="uk-preserve-width; uk-margin-right" uk-icon="pencil" onClick={() => setEditMode(true)} />
      <span className="uk-preserve-width; uk-margin-right" uk-icon="trash" onClick={() => onDelete()} />
      <Link to={`/FBLookup?list=${list._id}`}
        className="uk-preserve-width"
        uk-icon="search" />
    </td>
  </tr>;
}

export function ListEdit({ list }: { list: IUserListSummary }) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //    setSerialInput(event.target.value);
  };

  const handleEditAction = (event: React.FormEvent) => {
    event.preventDefault();
    //   if (serialInput && serialInput.length > 0) {
    //    getRackParts(serialInput);
    // }
  };

  return (
    <div className="uk-margin-small uk-padding-left uk-margin-left uk-margin-right uk-child-width-1-1" >
      <div className="uk-grid" >
        <form onSubmit={(e) => { handleEditAction(e); }}>
          <input type="text"
            className="uk-input uk-width-medium uk-inline"
            defaultValue={list.name}
            onChange={(e) => { onChange(e); }} />
          <button type="submit"
            className="uk-button uk-button-primary uk-inline" >Save</button>
        </form>
      </div>
    </div>
  );
}
