import { CSSProperties } from 'react';

const suggestionsContainer: CSSProperties = { position: 'absolute', zIndex: 2 };
const AutoSuggestTheme = {
  container: { display: 'inline-block' },
  suggestionsContainer,
  suggestionsList: { paddingLeft: '4px', paddingRight: '8px', cursor: 'pointer', border: '1px solid gray' },
};

export default AutoSuggestTheme;
