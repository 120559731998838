import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import LoginLink from 'src/components/LoginLink';
import { authContext } from 'src/contexts/AuthContext';
import { hideDropDown } from 'src/util/UIUtil';

const PrimaryNav = (props: React.HTMLAttributes<HTMLElement>) => {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  return (
    <Fragment>
      <nav className="uk-navbar-container uk-flex-column uk-flex-top uk-padding-none" data-uk-navbar data-uk-toggle="media: @s; cls: uk-flex-row uk-flex-top; mode: media">
        {
          // @ts-ignore -->
          <button type="button" data-uk-toggle="target: .navbar-collapse; cls: hidden-up-to-s uk-padding-remove"
            className="uk-navbar-toggle uk-hidden@s" data-uk-navbar-toggle-icon></button>
        }
        <div className="navbar-collapse hidden-up-to-s">
          <div className="uk-navbar-left" >
            {
              <ul data-uk-toggle="media: @s; cls: uk-navbar-nav uk-padding-remove; mode: media" className="uk-nav uk-nav-primary uk-padding-small">
                {!authToken &&
                  <li>
                    <LoginLink>Log In</LoginLink>
                  </li>
                }
                {authToken &&
                  <li className="uk-parent" >
                    <NavLink to="/FBLookup" activeClassName="uk-active">FB Part Lookup</NavLink>
                  </li>
                }
                {authToken &&
                  <li className="uk-parent" >
                    <NavLink to="/FBWhereUsed" activeClassName="uk-active">FB Where Used</NavLink>
                  </li>
                }
                {authToken &&
                  <li className={'uk-parent' + (window.location.pathname === '/FBForecast' && ' uk-active')}>
                    <NavLink to="/FBForecast" activeClassName="uk-active">FB Forecast</NavLink>
                  </li>
                }
                {authToken &&
                  <li className={'uk-parent' + (window.location.pathname === '/SerialLookup' && ' uk-active')}>
                    <NavLink to="/SerialLookup" activeClassName="uk-active">Serial Lookup</NavLink>
                  </li>
                }
                {authToken &&
                  <li className={'uk-parent' + (window.location.pathname.match(/\/Admin/) && ' uk-active')}>
                    <a href="#">Admin <span uk-icon="triangle-down"></span></a>
                    <div id="adminDropdown" uk-dropdown="pos: bottom-left; duration: 10; mode: click"
                      className="uk-margin-remove-top uk-padding-remove-top uk-navbar-dropdown">
                      <ul className="uk-nav">
                        <li onClick={() => hideDropDown('adminDropdown')}>
                          <NavLink to="/Admin_LoginHistory" activeClassName="uk-active">Login History</NavLink>
                        </li>
                        <li onClick={() => hideDropDown('adminDropdown')}>
                          <NavLink to="/Admin_LoadOperations" activeClassName="uk-active">Load Operations</NavLink>
                        </li>
                        <li onClick={() => hideDropDown('adminDropdown')}>
                          <NavLink to="/Admin_TaskList" activeClassName="uk-active">Tasks</NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      </nav>

    </Fragment >
  );
};

export default PrimaryNav;
