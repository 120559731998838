import dayjs from 'dayjs';

import { IForecast, InventoryStatusType } from 'src/types/IPart';

export interface IFacilityTotals {
  facility: Record<string, IForecast>;
}

export interface IStateTotals {
  remaining: number;
  parts: Record<string, IFacilityTotals>;
}

export const detailColumnCount = 26;
export const summaryColumnCount = 9;

export const detailHeaders = ['Expected Last 3 Months',
  ...Array.from({ length: 24 }, (v, k) => dayjs().add(k, 'months').format('MMM YY')),
  'Future'];
export const summaryHeaders = ['Expected Last 3 Months',
  ...Array.from({ length: 6 }, (v, k) => dayjs().add(k, 'months').format('MMM YY')),
  '1 Year',
  'Future'];
