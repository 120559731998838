import React, { Fragment, useContext, useEffect, useState } from 'react';

import LookupLink from 'src/components/LookupLink';
import WhereUsedLink from 'src/components/WhereUsedLink';
import { selectedFacilityContext } from 'src/contexts/SelectedFacilitiesContext';
import { ForecastColumn, ForecastFacilityNameColumn, ForecastInventoryColumns, ForecastSummaryColumn } from './ForecastLineElements';
import { selectedFacilityNames } from './ForecastUtils';
import { IStateTotals } from './interfaces';
import { detailColumnCount, summaryColumnCount } from './interfaces';

interface IForecastTotalLineProps {
  part: string;
  showDetail: boolean;
  totals: IStateTotals;
}

export const ForecastTotalLine = (
  props: React.HTMLAttributes<HTMLTableRowElement> & IForecastTotalLineProps,
) => {
  if (props.showDetail) {
    return <ForecastTotalDetailLine {...props} />;
  } else {
    return <ForecastTotalSummaryLine {...props} />;
  }
};

export const ForecastTotalSummaryLine = (
  props: React.HTMLAttributes<HTMLTableRowElement> & IForecastTotalLineProps,
) => {
  const selectedFacilities = useContext(selectedFacilityContext);
  const totals = props.totals;

  const facility = totals.parts[props.part] ? totals.parts[props.part].facility : {};

  return (
    <tr>
      <td className="uk-text-left uk-text-nowrap">
        <span className="uk-text-large">Part Totals for {props.part} </span>
      </td>
      <td >
        <span >{props.children}
          {props.totals.remaining ? <span>({props.totals.remaining} left)</span> : <span>-</span>}
        </span>
      </td>
      <ForecastFacilityNameColumn selectedFacilities={selectedFacilities.selectedFacilities} />
      <ForecastInventoryColumns forecast={facility} partQuantity={{}} totalPartsQuantity={1} />
      <ForecastColumn forecast={facility} index={0} partQuantity={{}} totalPartsQuantity={1} />
      {[...Array(summaryColumnCount - 3).keys()].map((i) =>
        <ForecastColumn key={`tot_${i}`} forecast={facility} index={i + 1} partQuantity={{}} totalPartsQuantity={1} />
      )}
      <ForecastSummaryColumn
        forecast={facility}
        start={summaryColumnCount - 2}
        end={summaryColumnCount + 5}
        partQuantity={{}}
        totalPartsQuantity={1} />
      <ForecastSummaryColumn
        forecast={facility}
        start={summaryColumnCount + 5}
        end={detailColumnCount}
        partQuantity={{}}
        totalPartsQuantity={1} />
      <td>
      </td>
      <td className="uk-text-nowrap">
        <LookupLink part={props.part} />
        <WhereUsedLink part={props.part} />
      </td>
    </tr>
  );
};

export const ForecastTotalDetailLine = (
  props: React.HTMLAttributes<HTMLTableRowElement> & IForecastTotalLineProps,
) => {
  const selectedFacilities = useContext(selectedFacilityContext);
  const totals = props.totals;

  const facility = totals.parts[props.part] ? totals.parts[props.part].facility : {};

  return (
    <tr>
      <td className="uk-text-left uk-text-nowrap">
        <span className="uk-text-large">Part Totals for {props.part} </span>
      </td>
      <td >
        <span >{props.children}
          {props.totals.remaining ? <span>({props.totals.remaining} left)</span> : <span>-</span>}
        </span>
      </td>
      <ForecastFacilityNameColumn selectedFacilities={selectedFacilities.selectedFacilities} />
      <ForecastInventoryColumns forecast={facility} partQuantity={{}} totalPartsQuantity={1} />
      <ForecastColumn forecast={facility} index={0} partQuantity={{}} totalPartsQuantity={1} />
      {[...Array(detailColumnCount - 1).keys()].map((i) =>
        <ForecastColumn key={`tot_${i}`} forecast={facility} index={i + 1} partQuantity={{}} totalPartsQuantity={1} />
      )}
      <td>
      </td>
      <td className="uk-text-nowrap">
        <LookupLink part={props.part} />
        <WhereUsedLink part={props.part} />
      </td>
    </tr>
  );
};
