import * as React from 'react';
import { config } from '../config';
import { authContext } from '../contexts/AuthContext';
import LoginLink from './LoginLink';

// At some point, the unauth case should be able to pass an override
// The children of the login element are showin insetead of "login".
// Use the <ProtectedRoute> component to limit access bsased on authentication

const SesameLogin = (
  props: React.HTMLAttributes<HTMLImageElement>,
  authServer: string) => {

  const auth = React.useContext(authContext);

  const loggedIn = (auth.authToken != undefined)
  if (loggedIn) {
    return (<div>{props.children}</div>);
  } else {
    return <LoginLink>
      <div className="uk-light uk-inline">
        <span uk-icon="icon: sign-in">Log in</span>
      </div>
    </LoginLink>
      ;
  }
};

export default SesameLogin;
