import React, { Fragment, useState, useEffect } from 'react';

interface IToolbarState { [key: string]: { active: boolean; updateWidget: (active: boolean) => void }; }
interface IToolbarAddWidget { key: string; updateWidgetFn: (active: boolean) => void; }
interface IToolbarChildProps { children: React.ReactElement[]; toggleFn?: (id: string) => void; }
interface IToolbarContainerProps { children: React.ReactElement[]; }
export interface IToolbarWidgetChildProps { active: boolean; setActive: () => void; }
interface IToolbarWidgetProps {
  children: React.ReactElement<IToolbarWidgetChildProps>;
  widget: string;
  active?: boolean;
  toggleFn?: (id: string) => void;
  addWidget?: (id: string, updateFn: any) => void;
}

interface IToolbarProps {
  children: React.ReactElement[];
}

export const useToolbar = (): [IToolbarState, (key: string) => void,
  (widgetId: string, updateFn: (active: boolean) => void) => void] => {

  const [toolbarState, setToolbarState] = useState<IToolbarState>({});

  const toggleState = (key: string) => {
    if (!key || !toolbarState[key]) {
      return;
    }

    // turn off anything that's on
    Object.keys(toolbarState).map((i: string) => {
      if (i !== key && toolbarState[i].active) {
        if (toolbarState[i].updateWidget) {
          toolbarState[i].active = false;
          toolbarState[i].updateWidget(false);
        }
      }
    });

    toolbarState[key].active = !toolbarState[key].active;
    if (toolbarState[key].updateWidget) {
      toolbarState[key].updateWidget(toolbarState[key].active);
    }
    setToolbarState({ ...toolbarState });
  };

  const addWidget = (widgetId: string, updateFn: (active: boolean) => void) => {

    if (!toolbarState[widgetId]) {
      toolbarState[widgetId] = {
        active: false,
        updateWidget: updateFn,
      };
      setToolbarState({ ...toolbarState });
    }
  };

  return [toolbarState, toggleState, addWidget];
};

export const ToolbarContainer = ({ children }: IToolbarContainerProps): any => {
  const [toolbarState, toggleState, addWidget] = useToolbar();

  return React.Children.map(children, (child) => {
    if (!React.isValidElement<IToolbarChildProps | IToolbarWidgetProps>(child)) {
      return child;
    }

    if (child.type === Toolbar) {
      const toolbarChild: React.ReactElement<IToolbarChildProps> = child as any;
      return React.cloneElement(toolbarChild,
        { toggleFn: toggleState });
    } else if (child.type === ToolbarWidget) {
      const toolbarChild: React.ReactElement<IToolbarWidgetProps> = child as any;
      const widgetId: string = toolbarChild.props.widget;
      const visible: boolean = toolbarState[widgetId] ? toolbarState[widgetId].active : false;
      return React.cloneElement(toolbarChild,
        {
          active: visible,
          addWidget: addWidget as any,
          toggleFn: toggleState as any,
        });
    }
    return child;
  });
};

export const Toolbar = ({ children, toggleFn }: IToolbarChildProps) => {

  return <Fragment>
    <nav className="uk-align-right uk-margin-remove-bottom uk-navbar-right" data-uk-navbar>
      <ul className="uk-navbar-nav">
        {
          React.Children.map(children, (child: React.ReactElement<HTMLElement>) => <li>
            <span className="uk-icon-link uk-padding-remove-right uk-margin-left uk-preserve-width" >
              {
                toggleFn ?
                  React.cloneElement(child as any, { onClick: () => toggleFn(child.props.id) })
                  : child
              }
            </span></li>)
        }
      </ul>
    </nav>
  </Fragment>;
};

export const ToolbarWidget = ({ children, widget, active, toggleFn, addWidget }: IToolbarWidgetProps) => {

  const updateFn = () => {
    if (toggleFn) {
      toggleFn(widget);
    }
  };

  useEffect(() => {
    if (addWidget) {
      addWidget(widget, toggleFn);
    }
  }, []);

  if (active) {
    return <div className="uk-card uk-card-default uk-width-auto uk-box-shadow-large" >
      <button className="uk-modal-close-default uk-position-top-right"
        onClick={() => toggleFn ? toggleFn(widget) : console.log('no close function defined')}
        type="button" data-uk-close></button>
      {React.cloneElement(children as any, { active: true, setActive: updateFn })}
    </div>;
  }
  return React.cloneElement(children as any, { active: false, setActive: updateFn });
};
